@import 'src/styles/mixins';
@import 'src/styles/variables';

.settings-tab {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  background-color: $comp-background-color;

  &__main {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;

    &__flow {
      display: flex;
      flex: 1;
      width: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      padding: $spacer-2xlarge*5;
      box-sizing: border-box;
    }

    &__progress {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex: 1;
      padding: $spacer-2xlarge*4;
      width: 100%;
      height: 100%;
    }

    &__settings {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: $spacer-2xlarge*3;
      flex: 1;
      width: 100%;
      height: 100%;
      max-width: 1000px;
      box-sizing: border-box;
      background: white;
      border: 1px solid $border-color;
      align-self: center;

      &__title {
        font-weight: bold;
        //margin-bottom: $spacer-2xlarge;
      }

      &__text {
        margin-top: $spacer-large*2;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        &__descr {
          margin-left: $spacer-large;
          font-size: $font-size-small;
          color: $neutral-color-700;
        }
        &__button {
          margin-left: $spacer-large;
        }
      }

      &__search {
        svg {
          display: none;
        }
        &>div>div>div>button>span.flight-button__label{
          text-align: left !important;
        }
      }

      &__section {
        display: flex;
        flex: 1;
        width: 100%;
        flex-direction: column;
        padding: 0 0 $spacer-2xlarge*2 0;
        border-bottom: 1px solid $border-color;
        margin-bottom: $spacer-2xlarge*2;
        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
          padding: 0;
        }
      }

      &__group {
        padding: $spacer-large/2 $spacer-large/2 $spacer-large/2 $spacer-large;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        margin: 0 $spacer-large $spacer-large/2 0;
        button {
          background-color: transparent;
          border: none;
          cursor: pointer;
          &:hover {
            color: $primary-blue;
          }
        }
      }
    }

    &__error {
      color: red;
      text-align: center;
      padding: 60px;
    }

    &__empty {
      text-align: center;
      padding: 60px;
    }
  }

}
