@import 'src/styles/mixins';
@import 'src/styles/variables';

.label-input {
  display: flex;
  width: 100%;
  flex-direction: column;

  &__text {
    margin-top: $spacer-large;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    &__descr {
      margin-left: $spacer-large;
      font-size: $font-size-small;
      color: $neutral-color-700;
    }
    &__button {
      margin-left: $spacer-large;
    }
  }

  &__description {
    color: $neutral-color-600;
    font-size: $font-size-small;
    padding: $spacer-large $spacer-large 0 $spacer-large;
  }

  &__label {
    padding: $spacer-large/2 $spacer-large/2 $spacer-large/2 $spacer-large;
    background-color: $blue-50;
    border-radius: $border-radius;
    margin: 0 $spacer-large $spacer-large/2 0;
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      &:hover {
        color: $primary-blue;
      }
    }
  }

  &__error {
    color: red;
    text-align: center;
    padding: 60px;
  }
}
