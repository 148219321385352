@import 'src/styles/variables.scss';

.push-delivery {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 10px;
  padding: 5px;

  &__heading {
    font-weight: bold;
    margin: 30px 0 5px;
  }

  &__description {
    font-size: 90%;
    margin: 0 0 10px 0;
    color: $neutral-color-700
  }

  &__input {
    margin-top: 10px;
  }

  &__inline {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    &:last-child {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
    }
  }

  &__subline {
    margin-top: 10px;
  }

  &__number, &__limit {
    max-width: 50px;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__limit {
    max-width: 100px;
  }

  &__select {
    display: inline-flex;
    width: auto;
    max-width: 100px;
  }

  &__delay {
    width: auto;
    max-width: 100px;
  }

  &__limit-selector {
    margin: 0 10px;
  }

  &__checkbox {
    position: relative;
  }

  &__question-icon {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 5px;
  }

}
