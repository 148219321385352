@import 'src/styles/mixins';
@import 'src/styles/variables';


.templated-experiences-edit {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  background-color: $comp-background-color;

  &__main {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;

    &__flow {
      display: flex;
      width: auto;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: $spacer-2xlarge*5 $spacer-2xlarge*4;
      box-sizing: border-box;
    }

    &__progress {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex: 1;
      padding: $spacer-2xlarge*4;
      width: 100%;
      height: 100%;
    }

    &__error {
      color: red;
      text-align: center;
      padding: 60px;
    }

    &__empty {
      text-align: center;
      padding: 60px;
    }
  }

  &__finish-icon {
    margin: 75px 0 0 $spacer-2xlarge;
  }

}
