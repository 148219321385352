
.setting-edit {
  .block {
    margin-top: 40px;
    &-indent {
      margin-left: 25px;
      &.disabled {
        color: rgb(201, 201, 201);
      }
    }
  }
  p, h2, h3 {
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 14px;
  }

  small {
    display: block;
    padding: 10px 0px;
    color: gray;
  }

  .flight-radio-button-wrapper {
    .flight-radio-button {
      margin: 10px 0px;
    }
  }

  .flight-number-input-wrapper {
    margin: 0px 10px;
  }

  .row {
    display: flex;
    align-items: center;
  }

}