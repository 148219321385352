@import 'src/styles/variables';
@import "src/styles/mixins";

$bar-height: 80px;
$bar-height-xl: 130px;

.control-bar {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: transparent;
  height: 100%;

  &__toggle-switch {
    margin-right: $spacer-2xlarge;
    display: inline;
    white-space: nowrap;
    font-size: 100%;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: $bar-height;
    padding: 10px 0;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    box-sizing: border-box;
    background: white;
    z-index: 2;

    &-left {
      padding-left: 20px;
      flex: 2;
      &__name,
      &__title {
        min-width: 400px;
        width: 100%;
      }
      &__title {
        font-size: 24px;
        padding: 0 20px 0 0;
        line-height: 1.2;
      }
      input {
        min-width: 400px;
        width: 100%;
        background-color: transparent;
        font-size: 24px;
        border-bottom: none;
      }
      &__search {
        margin-left: $spacer-2xlarge * 3;
        flex: 2;
        &__name {
          min-width: 400px;
          width: 100%;
        }
        input {
          width: 100%;
          font-size: 24px;
          border-bottom: none;
        }
      }
    }
   

    &-right {
      padding-right: 20px;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-end;
      button {
        margin-right: 10px;
      }
    }
  }

  &__tab {
    .tab {
      width: 100%;
      display: flex;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      box-sizing: border-box;
      background: white;
      z-index: 2;
      align-items: center;
      justify-content: center;

      &__content {
        position: relative;
        width: auto;
        &-link {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          position: relative;
          width: 100%;
          a {
            cursor: pointer;
            border: none;
            width: 150px;
            color: black;
            font-size: 16px;
            text-align: center;
            &:active {
              outline: 0;
            }
            &.selected {
              animation: all 1s ease;
              font-weight: 600;
            }
            text-decoration: none;
            padding: 15px 0;
          }
        }
      }

      .underline {
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all 0.5s ease;
        width: 150px;
        height: 1px;
        background: $primary-blue;
        border: 1px solid $primary-blue;
        &.first {
          transform: translateX(0px);
        }
        &.second {
          color: blue;
          transform: translateX(150px);
        }
        &.third {
          color: blue;
          transform: translateX(300px);
        }
      }
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: scroll;
  }

  &__status {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;

    &__text {
      margin-left: 8px;
      text-transform: uppercase;
    }
  }
}

