@import "src/styles/variables";

.handler {

  &.in,
  &.launched,
  &.opened,
  &.not_opened {
    top: 50%;
    background: transparent;
    border-radius: 0;
    right: auto;
    left: -8px;
    width: 0;
    height: 0;
    cursor: e-resize;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid $neutral-color-600;
    border-right: 0px solid transparent;
  }

  &.opened,
  &.launched {
    left: auto;
    right: -8px;
    border-left: 8px solid $green-400;
  }

  &.not_opened {
    left: auto;
    right: -8px;
    border-left: 8px solid $red-400;
  }

  &.level-1 {
    top: 10%;
  }
  &.level-2 {
    top: 30%;
  }
  &.level-3 {
    top: 50%;
  }
  &.level-4 {
    top: 70%;
  }
  &.level-5 {
    top: 90%;
  }
}
