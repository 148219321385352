@import 'src/styles/mixins';
@import 'src/styles/variables';

.multi-action {
  display: flex;

  &__card-container {
    .flight-card {
      min-height: 0px;
    }

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    &__button {
      display: flex;
      flex-direction: column;
      padding: 0;
      background-color: $primary-white;
      border-radius: $border-radius * 2;
      border: $border-width * 2 solid $border-color;
      margin: 0 $spacer-2xlarge;
      margin-bottom: $spacer-2xlarge;
      box-sizing: border-box;
      box-shadow: none;
      transition: all 300ms;

      &__no-right-margin {
        margin: 0 0 0 $spacer-2xlarge;
      }

      &.error {
        border: $border-width * 2 solid rgb(240, 117, 79);
        box-sizing: border-box;
      }

      &.not-clickable {
        cursor: default;
      }
    }

    &__card {
      &:focus {
        outline: none;
      }

      &__step-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: $spacer-2xlarge;
        padding-bottom: 0px;
        margin-bottom: -$spacer-large;
        color: $neutral-color-600;

        &__type-box {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        &__icon {
          margin-right: 15px;
          width: 30px;
          height: 30px;
        }

        &__question {
          width: 25px;
          height: 25px;
          fill: $neutral-color-500;
        }

        &__edit {
          display: none;
        }

        &__edit-show {
          display: block;
          width: 20px;
        }

        &__error-icon {
          color: $secondary-red;
          fill: $secondary-red;
          svg {
            fill: $secondary-red;
          }
        }

        &__container-actions {
          cursor: pointer;
          color: $blue-500;
          font-size: $font-size-small;
        }
      }

      &__step-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        padding: $spacer-large * 2;

        & .minicard__content {
          max-width: 270px;
        }

        &--space-bottom {
          padding-bottom: $spacer-large * 4;
        }

        &__title {
          font-weight: bold;
          font-size: 90%;
          margin: 40px 0 20px 0;
        }

        &__content {
          margin-bottom: 35px;
          margin-top: 25px;
          padding: 5px;
        }

        &__descr {
          padding: 10px 0px;
          color: $neutral-color-600;
          font-size: 15px;
          .anyone {
            &__heading {
              color: #1e2b4a;
              font-size: 20px;
              font-weight: normal;
            }
            p {
              color: $neutral-color-600;
              font-size: 14.5px;
            }
          }
          .empty-audience {
            width: auto;
            margin-top: 5px;
            box-shadow: none;
            background-color: $yellow-50;
            padding: $spacer-2xlarge;
            display: flex;
            justify-content: flex-start;
            border-radius: $border-radius;
            .tab-icon svg {
              fill: $yellow-600;
              width: 20px;
              margin-right: 15px;
              transform: rotate(180deg);
            }
            .flight-snackbar__icon {
              top: 25px;
            }
            .flight-snackbar__content {
              margin-bottom: 0px;
              padding-left: 30px;
              color: $neutral-color-400;
              line-height: 17px;
            }
          }
        }

        &__plugins {
          font-size: 14px;
          display: flex;
          margin-top: 5px;
          padding: 5px;

          &__plugin-left {
            padding-right: 3px;
          }

          &__plugin-right {
            display: flex;
          }
        }

        &__placeholder {
          margin: 0 $spacer-large * 2;
          font-size: 90%;
          color: $neutral-color-500;

          &--space-bottom {
            padding-bottom: $spacer-large * 2;
          }
        }

        &__delivery {
          border-top: 1px solid $border-color;
          text-align: left;
          padding: $spacer-large * 2 $spacer-large * 4;
          border-radius: 0 $border-radius $border-radius 0;
          line-height: 160%;
          font-size: 90%;
          color: $neutral-color-700;
          &__title {
            font-size: 110%;
            font-weight: bold;
            margin-bottom: 10px;
          }
        }
      }
    }

    &__arrow {
      margin-top: 80px;
      margin-left: -20px;
    }
  }
}
