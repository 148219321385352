.minibox {
  margin: 10px 5px;
  p {
    margin: 12px 0px;
  }
  &-content {
    &--lightbg {
      background: white;
      &:hover {
        opacity: 0.75;
      }
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 15px 10px;
      cursor: pointer;
      > header {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        .title {
          font-weight: bold;
          font-size: 15px;
        }
        img {
          margin-right: 10px;
        }
      }
      > section {
        padding: 5px;
        p {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
