
.theme-hub {
  padding: 40px;
  .flight-text-input-wrapper {
    margin-top: 10px;
    margin-bottom: 15px;
  }
  &__section {
    padding: 10px;
  }
  &__color-picker {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  &__snack-bar {
    position: relative !important;
    margin-left: -3px;
    box-shadow: none !important;
    margin-bottom: 10px;
  }
  .flight-button {
    margin-top: 10px;
  }
}