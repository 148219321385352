.analytics-stat-card {
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #ddd;
  box-sizing: border-box;
  padding: 25px 25px 35px 25px;

  // reset style on p tag
  p {
    margin: 0;
    padding: 0;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 40px;
    .label {
      margin: 0;
    }
    .tooltip {
      position: absolute;
      right: 0;
      cursor: pointer;
      margin-left: 10px;
      img {
        margin-top: 5px;
        width: 20px;
        height: 20px;
      }
    }
  }

  &__bottom {
    margin-top: 20px;
    text-align: center;
    .value {
      margin: 0;
      font-size: 40px;
    }
  }
}
