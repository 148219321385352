@import 'src/styles/variables';

.content-modal-preview {
  position: absolute;
  width: 237.3px;
  height: 446.5px;
  right: 12%;
  top: 17%;
  @media only screen and (max-width: 1000px) {
    visibility: hidden;
  }
  &__arrow-right {
    float: right;
    top: 45%;
    position: relative;
    left: 13%;
    z-index: 10;
  }
  &__arrow-left {
    float: left;
    top: 45%;
    position: relative;
    right: 12%;
    z-index: 10;
  }

  .dynamic-attr-wrapper, .dynamic-attr {
    border: 1px solid #CDD0D7;
    border-radius: 2px;
    display: inline-block;
    margin: 0px 3px;
    padding: 0px 3px;
    cursor: pointer;
    margin-bottom: 2px;
    word-break: break-all;
    .dynamic-attr__default-value {
      color: #0851DF;
      padding-left: 4px;
    }
  }

  &__preview-body {
    &__tooltip {
      .flight-tooltip__tooltip {
        z-index: 200 !important;
        width: unset !important;
      }
    }

    &__loader {
      width: 196px;
      height: 70px;
      align-items: center;
      align-content: center;
      margin-right: auto;
      margin-left: auto;
    }
    &__no-content {
      color: $neutral-color-300;
      font-weight: 800;
      margin-top: 150px;
      align-self: center;
      text-align: center;
      &__card {
        background-color: white;
        display: flex;
        flex-direction: column;
        width: calc(100% - 65px);
        margin-right: 20px;
        margin-left: 20px;
        background-color: white;
        border-radius: $border-radius * 2;
        padding: 12px;
        height: calc(20% - 25px);
        align-self: center;
        border: 1px solid $border-color;

        &__title {
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          align-items: center;
          text-align: center;
          color: #4e5b74;
        }

        &__header {
          margin-top: 10px;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          align-items: center;
          text-align: center;
          color: rgba(0, 0, 0, 0.401863);
        }
      }
    }
    &__content {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 296.5px;
      margin-top: 90px;
      display: flex;
      flex-direction: column;
      width: calc(100% -65px);
      margin-right: 20px;
      margin-left: 20px;
      border-radius: $border-radius * 2;
      padding: 12px;
      align-self: center;
      background: #ffffff;
      border: 1px solid $border-color;
      text-align: left;

      &__header-img {
        border-top-left-radius: $border-radius * 2;
        border-top-right-radius: $border-radius * 2;
        width: 197px;
        height: 75px;
        object-fit: cover;
        margin-left: -12px;
        margin-top: -15px;
      }
      &__header {
        margin-top: 10px;
        font-weight: 600;
        font-size: 10px;
        line-height: 20px;
        letter-spacing: -0.192px;
        color: rgba(0, 0, 0, 0.401863);

        &__wrap {
          max-width: 168px;
        }
      }

      &__title {
        font-size: 16px;
        font-weight: bold;
        display: flex;
        line-height: 24px;
        flex-direction: row;
        width: 150px;
        align-items: center;
        word-wrap: break-word;
        z-index: 5;

        &__wrap {
          max-width: 168px;
        }
      }

      &__body {
        width: 100%;
        font-size: 11px;
        margin: 5px 0 0 0;
        line-height: 1.3em;
        margin-bottom: 10px;
        &__showmore {
          color: $primary-blue;
          cursor: pointer;
        }
      }

      &__link {
        cursor: pointer;
        color: $primary-blue;
      }

      &__button {
        &--primary {
          color: #ffffff;
          background-color: $primary-blue;
          align-self: center;
          text-align: center;
          margin: 5px;
          padding: 6px;
          border-radius: 30px;
          min-width: 128px;
          max-height: 18px;
          font-size: 11px;
        }
        &--secondary {
          color: $primary-blue;
          background-color: #ffffff;
          border: 1px solid $primary-blue;
          align-self: center;
          text-align: center;
          margin: 5px;
          padding: 6px;
          border-radius: 30px;
          min-width: 128px;
          max-height: 18px;
          font-size: 11px;
        }
      }

      &__your-app-icon {
        margin-right: 5px;
      }
    }
    &__detailed-content {
      overflow-y: auto;
      overflow-x: hidden;
      background: #ffffff;
      border-radius: 20px;
      height: 433px;
      margin-top: 7px;
      margin-left: 5px;
      margin-right: 9px;

      &__content {
        padding: 13px;
        &__header-img {
          position: relative;
          margin-left: -40px;
          width: calc(100% + 80px);
          height: 110px;
          object-fit: cover;
          margin-top: 15px;
        }

        &__header {
          padding-top: 10px;
          font-weight: 600;
          font-size: 10px;
          line-height: 16px;
          letter-spacing: -0.192px;
          color: #505a72;
        }

        &__title {
          padding-top: 10px;
          font-size: 16px;
          font-weight: bold;
          display: flex;
          line-height: 24px;
          flex-direction: row;
          align-items: center;
          }

        &__body {
          width: 100%;
          font-size: 11px;
          margin: 5px 0 0 0;
          line-height: 1.3em;
          min-height: 55px;
          text-overflow: ellipsis;
          margin-bottom: 10px;
          }
      }
    }
  }
}

.content-modal-cards {
  color: #82899b;
  font-weight: bold;
  font-size: 11px;
  line-height: 18px;
  margin-left: 15%;
}
.content-modal-cards-modal {
  color: #82899b;
  font-weight: bold;
  font-size: 11px;
  line-height: 18px;
  margin-left: 30%;
}
.preview__footer-text {
  @media only screen and (max-width: 1000px) {
    visibility: hidden;
  }
  color: $neutral-color-500;
  top: 84%;
  right: 16%;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  position: fixed;
}
