@import 'src/styles/variables.scss';


.action-edit {
  height: 100%;
  &-select {
    margin-top: 10px;
    ul {
      padding: 0;
      margin: 15px 0px;
      display: flex;
      li {
        cursor: pointer;
        list-style: none;
        margin-right: 10px;
        padding: 0px 5px;
        &:active,
        &:focus {
          outline: none;
        }
        &.selected {
          border-bottom: 3px solid $primary-blue;
        }
      }
    }
  }
  &-main {
    padding: 10px 0px;
    .push {
      h3 {
        margin-bottom: 20px;
      }
      .textarea {
        margin-top: 10px;
      }
    }
  }
}
