@import 'src/styles/variables';

.trigger-box {
  &__summary {
    margin-top: 20px;
  }

  &__plugin-name {
    font-weight: $font-weight-xbold;
  }

  &__description,
  &__dayparting {
    padding: 15px 0;
    color: $neutral-color-600;
    font-size: 15px;
  }

  &__dayparting {
    padding: 5px 0 0;
  }
  &__location {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $border-color;
  }
  &__push-container,
  &__location-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    svg {
      margin-right: 10px;
    }
  }
  &__location-container {
    font-size: $font-size-xsmall;
    color: $teal-600;
  }

  &__push {
    div:first-child {
      font-size: $font-size-xsmall;
      color: $purple-300;
    }
  }
}
