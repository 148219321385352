@import 'src/styles/variables';
@import 'src/styles/mixins';

.performance-empty-state {
  width: 100%;
  margin: 40px auto;
  text-align: center;

  &__image, &__title {
    margin-bottom: $spacer-large;
  }

  &__image {
    width: 150px;
    height: 150px;
  }

  &__title {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    line-height: $line-height-large;
  }

  &__description {
    font-size: $font-size-small;
    line-height: $line-height-small;
    margin-bottom: $spacer-2xlarge;
  }
}
