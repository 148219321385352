.searchBox {
  align-self: center;
}
.searchBox input {
  width: 100%;
  height: 2.8rem;
  border: none;
}

.searchBox {
  border-bottom: none;
}

.searchBox input[type='text'] {
  width: 100%;
  height: 25px;
  box-sizing: border-box;
  background-color: #ffffff;
  background-image: url('../../../assets/icons/search-icon.svg');
  background-position: 10px 10px;
  background-repeat: no-repeat;
  border: 1px solid #E6E7EB;
  margin-top: -9px;
  border-radius: 5px;
  padding:20px 37px 17px 34px;
  outline: 0;
  line-height: normal;
  letter-spacing: normal;
  color: #707070;

}
.closeSearch {
  margin-left: -19px;
  background-color: #ffffff;
  position: absolute;
  margin-top: 5px;
  cursor: pointer;
  width: 11px;
  height: 11px;
}
.search {
  display: inline-block;
  padding: 6px 5px 4px;
  vertical-align: top;
}
.search:hover {
  cursor: pointer;
}
