@import 'src/styles/variables';

.template-side-panel {
  &__content {
    width: 400px;
    max-width: 400px;
  }

  &__carousel {
    width: 100%;
    .flight-carousel-item {
      margin: 0 20px 0 0;
    }
  }

  &__thumbnail {
    max-width: 115px;
    max-height: 104px;
    box-shadow: 0 4px 3px -2px $neutral-color-100;
    cursor: pointer;
  }

  &__play-btn {
    position: absolute;
    left: calc(50% - 19px);
    top: calc(50% - 19px);
    cursor: pointer;
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 5px;
  }

  &__detail {
    display: flex;
    flex-direction: column;
    width: 115px;
    margin: 22px 15px 0 0;

    &__title {
      font-size: $font-size-small;
      font-weight: $font-weight-regular;
      color: $neutral-color-400;
      margin-bottom: 10px;
    }

    &__text {
      font-size: $font-size-medium;
      font-weight: $font-weight-regular;
      color: $neutral-color-900;
      word-break: break-word;
      &--bold {
        font-weight: $font-weight-bold;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    font-size: $font-size-medium;

    &__title {
      font-weight: $font-weight-bold;
      color: $neutral-color-900;
    }

    &__text {
      font-weight: $font-weight-regular;
      color: $neutral-color-900;
      margin-top: 10px;
      word-break: break-word;
      &--bold {
        font-weight: $font-weight-bold;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__favourite {
      display: flex;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;
    }

    &__text {
      font-size: $font-size-xsmall;
      font-weight: $font-weight-bold;
      color: $blue-500;
      margin-left: 15px;
    }

    &__button {
      margin-left: 10px;
    }
  }
}
