@import 'src/styles/variables';

$width-field: 367px;
.labels {
  .flight-select-searchable__dropdown__input .flight-text-input__icon-trailing {
    visibility: hidden !important;
  }
  .flight-text-input-wrapper {
    width: $width-field !important;
  }
  .flight-dropdown__dropdown {
    width: $width-field !important;
  }
  //required as flight dropdown over flight modal
  // has overlapping due to conflicting z-index.
  .flight-dropdown__dropdown {
    top: auto !important;
    z-index: 105 !important;
  }

  &__container {
    max-width: $width-field;
    display: flex;
    flex-wrap: wrap;
    font-size: $font-size-small;
    margin-left: -5px;
    overflow-y: visible;
    &__tabs {
      cursor: pointer;
      display: inline-block;
      margin-top: 10px;
      margin-left: 5px;
      padding: 10px;
      background-color: #f2f3f5;
      color: $primary-black;
      border-radius: 5px;
      padding-top: $spacer-medium;
      padding-bottom: $spacer-medium;
      padding-left: $spacer-large;
      font-weight: $font-weight-bold;
      font-size: $font-size-small;
      flex-wrap: wrap;

      &__close {
        cursor: pointer;
        border-radius: 50%;
        color: #eee;
        background: #d3d3d3;
        position: relative;
        font-size: 10px;
        padding: 1px 5px;
        padding-bottom: 2px;
        bottom: 2px;
        margin-left: 15px;
      }

      &__close:hover {
        background: #bbb;
      }
    }
    &__tabs:hover {
      background-color: #eee;
    }
  }
}
