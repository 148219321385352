@import 'src/styles/mixins';
@import 'src/styles/variables';

.flowbox-v2 {
  display: flex;

  &__main {
    border-radius: $border-radius*2;
    border: $border-width * 2 solid $border-color;
    width: 450px;
    background-color: white;
    > header {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      border-bottom: 1px solid $border-color;
    }
    > section {
      padding: 1em;
      .box-header {
        display: flex;
        img {
          margin-right: 10px;
        }
      }
    }
  }

  &__bridge {
    height: 50px;
    display: flex;
    align-items: center;
    .flight-dropdown__dropdown {
      margin-top: 5px;
      padding: 0;
    }
    &-dropdown {
      cursor: pointer;
      width: 150px;
      hr {
        padding: 0;
        margin: 0;
      }
      p {
        margin: 0;
        padding: 10px;
        &:hover {
          background: #eee;
        }
      }
    }
  }

}
