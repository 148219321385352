@import 'src/styles/variables';

.notification-dropdown {
  max-height: 730px;
  width: 480px;
  overflow: auto;
  position: absolute;
  background-color: $neutral-color-0;
  margin-top: 35px;
  left: calc(100vw - 559px);
  border-radius: $border-radius;
  box-shadow: 0px 1px 4px $neutral-color-300;

  &__container {
    padding: $spacer-2xlarge;
    &__heading {
      margin-top: $spacer-large;
      font-weight: $font-weight-bold;
    }
    &__section {
      padding-top: 40px;
      &__mark-all-read {
        float: right;
        margin-top: 38px;
        color: $primary-blue;
        &:hover {
          text-decoration: underline;
        }
      }
      &__loading {
        margin-top: 10px;
      }
    }

    &__no-notifications {
      color: $neutral-color-600;
      padding: 135px;
      font-size: $font-size-xsmall;
    }

    &__content {
      cursor: pointer;
      padding: $spacer-large;
      margin-left: -20px;
      margin-right: -20px;
      margin-top: $spacer-large;
      font-size: $font-size-small;
      &__icon {
        position: absolute;
      }
      &__name {
        margin-left: 13%;
        position: relative;
        display: inline-block;
        top: -12px;
        width: 350px;
        &--bold {
          font-weight: $font-weight-bold;
        }
      }

      &__tool-tip {
        display: unset !important;
        position: relative !important;
        margin-left: 93%;
        bottom: 10px;
      }
     
      &__timestamp {
        margin-left: 13%;
        font-size: $font-size-xsmall;
        color: $neutral-color-500;
      }
      &__read-indicator {
        margin-left: 430px;
        position: absolute;
      }
      &:hover {
        background-color: $blue-50;
        margin-left: -20px;
        margin-right: -20px;
      }
    }
  }
}
