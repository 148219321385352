@import 'src/styles/variables';

.content-edit-modal {
  max-width: 487px !important;
  overflow-y: auto !important;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  &__content {
    margin-left: auto;
    margin-right: auto;

    &__heading {
      font-weight: bold;
      margin: 10px 0 10px 0;
    }

    &__input {
      margin-top: 10px;
    }

    &__type {
      font-weight: $font-weight-bold;
      padding-right: 20px;
      &__name {
        padding-left: $spacer-small;
        font-size: $font-size-medium;
      }
    }

    &__icon {
      padding-top: 20px;
      display: inline-block;
      &__img {
        width: 45px;
        height: 45px;
        object-fit: cover;
      }
      &__img-tool-tip {
        width: 75px;
        height: 75px;
        object-fit: cover;
      }
      &__options {
        display: inline-block;
        height: 52.5px;
        padding-left: 20px;
        vertical-align: middle;
        cursor: pointer;
        color: $primary-blue;
        font-weight: $font-weight-bold;
      }
    }
  }
}
