@import 'src/styles/mixins';
@import 'src/styles/variables';

.audience-iframe {
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;

  &.hidden {
    display: none;
  }
  .flight-snackbar {
    box-shadow: none !important;
    margin-top: 20px;
    width: auto;
    padding-right: 40px;
    height: auto;
  }
  .iframe-container {
    flex: 1;
  }
  .audience-select {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    button.help {
      svg {
        fill: $neutral-color-700;
      }
      color: $neutral-color-700;
    }
    button {
      color: $primary-blue;
      svg {
        fill: $primary-blue;
      }
    }
  }
}

.audience-iframe-guide {
  display: flex;
  padding: 35px 0px;
  &__image {
    min-width: 220px;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    img {
      height: 100%;
    }
  }
  &__detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    h2 {
      font-size: 25px;
      font-weight: 450;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
    }
    button {
      margin-top: 15px;
      padding: 20px;
    }
  }
}
