@import 'src/styles/variables';

.media-preview-modal {
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all ease 300ms;
  opacity: 1;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &__content {
    width: 700px;
    height: 450px;
    border-radius: 4px;
    margin: 10% auto;
    transition: all ease 400ms;
    background-color: $primary-white;
  }

  &__video {
    width: 698px;
    height: 393px;

    &.loading {
      display: none;
    }
  }

  &__image-container {
    display: flex;
    justify-content: center;
    background-color: $neutral-color-50;
  }

  &__image {
    max-width: 698px;
    max-height: 393px;
    width: auto;
    height: auto;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    width: 95%;
    margin: 15px 20px;
  }
}
