@import 'src/styles/variables';
@import 'src/styles/mixins';

.performance-loading-state {
  height: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: map-get($neutral-colors, '800');

  &__icon {
    margin-top: $spacer-xlarge;
    svg {
      @include animation(spin, 0s, 0.75s, linear, infinite, normal, none, running);
      fill: #0851DF;
    }
  }

  &--large {
    height: 450px;
  }

  &--small {
    height: 150px;
  }
}
