@import 'src/styles/variables';

.trigger-edit-modal {
  &__content {
    display: flex;
    flex-direction: column;
    font-size: 120%;
    margin: 20px;
    width: 100%;
    min-height: 200px;
  }

  &__header {
    display: flex;

    &-icon {
      margin-right: 10px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 10px;
    }
  }

  &__label {
    font-weight: bold;
    margin: 10px 20px 10px 0;
  }

  &__select, &__select-event {
    min-width: 100% !important;
    margin-bottom: 16px;
    input {
      min-width: 100% !important;
    }
    label {
      min-width: 100% !important;
    }
    div>div>div{
      min-width: 100% !important;
    }
  }

  &__select-event {
    margin-top: 15px;
  }

  &__select-timezone {
    margin-left: 10px;
  }

  &__select-day, &__select-period {
    margin: 0 10px;
    max-width: 120px;
    label {
      max-width: 120px;
    }
    input {
      max-width: 120px;
    }
  }

  &__select-time {
    margin: 0 10px !important;
    max-width: 100px;
    label {
      max-width: 100px;
    }
    input {
      max-width: 100px;
    }
  }

  &__audience {
    font-size: 90%;
    padding: 16px 10px;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    margin-top: 20px;
  }

  &__descr {
    font-size: 80%;
    color: $neutral-color-500;
  }

  &__inline {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: 80%;
    margin-top: 15px;

    &__text {
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 40px;
    }
  }

  &__checkbox {
    position: relative;
    float: left;
  }

  &__link-box {
    margin-top: 30px;
  }

  &__link {
    font-size: 90%;
    color: $link-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: $link-hover-color;
    }
  }

  &__remove-icon {
    margin-left: 0;
  }

  &__number {
    margin-left: 10px;
  }

  &>.flight-modal__content {
    overflow: visible !important;
  }
}
