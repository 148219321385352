@import 'styles/variables.scss';

.analytics-performance-optimized-chart {


  .custom-tooltip {
    background: $primary-black;
    color: white;
    font-size: 13px;
    padding: 10px;
    width: auto;
    .row {
      display: flex;
      justify-content: space-between;
      color: map-get($neutral-colors, "300");
      p {
        margin: 0;
        padding: 0;
      }
      p:first-child {
        margin-right: 10px;
      }
      &.highlight {
        color: $blue-100;
      }
      &.updated {
        color: $yellow-500;
      }
    }
  }

  &__main {
    flex: 1;
    height: 500px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;

    .top {
      .graph-button {
        svg {
          fill: $primary-blue;
        }
        color: $primary-blue;
      }

      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 60px;
      p {
        margin: 0;
        padding: 0;
        &.headline {
          font-size: 23px;
        }
      }
    }

    .status {
      position: relative;
      width: 100%;
      z-index: 2;
      .flight-snackbar {
        margin-top: 20px;
        width: 97%;
      }
    }

    .snackbar {
      top: 0;
      position: relative;
    }

    .chart {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;

      .label {
        opacity: 0.7;
        text-anchor: middle;
      }
      .reference-line {
        opacity: 0.5;
        font-size: 14px;
        font-weight: bold;
      }
      .line {
        stroke-width: 3px;
      }

      &__overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: $primary-white;
        opacity: 0.7;
        top: 0;
        left: 0;
        z-index: 2;
      }

      &__warning {
        padding: 30px;
        border: 1px solid $primary-blue;
        background-color: $primary-white;
        border-radius: $border-radius*4;
        text-align: center;
        width: 100%;
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

        &__text {
          margin: 20px auto;
        }
      }
    }
  }

  &__control {
    position: relative;
    margin-top: 35px;
    margin-left: 90px;
    .range-slider {
      margin-bottom: 45px;
    }
    .button-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      button {
        margin-right: 10px;
      }
      .remove-button {
        background: $secondary-red;
        &:hover {
          background: map-get($reds, "600");
        }
        &:active:enabled {
          background: map-get($reds, "700");
        }
      }
    }
  }

  &__targeting-details {
    color: $primary-blue;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 10px;
    margin-top: 30px;

    .arrow-right {
      transform: rotate(0deg);
      margin-right: 10px;
      &.active {
        transform: rotate(90deg);
      }
    }
  }

  &__details-description {
    margin: 30px 0;
    a {
      color: $primary-blue;
      text-decoration: none;
    }
  }

  &__suggested-model {
    color: $neutral-color-500;
    display: flex;
    flex-direction: row;

    &__marker-current {
      width: 15px;
      height: 15px;
      float: left;
      margin-right: 10px;
      margin-top: 2px;
      background-color: $primary-blue;
    }

    &__marker-updated {
      width: 15px;
      height: 15px;
      float: left;
      margin-right: 10px;
      margin-top: 2px;
      background-color: $secondary-yellow;

      &.margin-left {
        margin-left: 40px;
      }
    }
  }

  &__table-container {
    display: flex;
    flex-direction: row;
  }

  &__table {
    margin: 30px 0;
    padding: 0;
    min-width: 65%;

    thead {
      background-color: $neutral-color-50;
    }
    th, td {
      font-weight: normal;
    }

    &__attribute {
      display: flex;
      align-items: center;
      font-size: 110%;
      strong {
        margin-left: 5px;
      }
      &.inactive {
        color: $neutral-color-400;
      }
    }

    &__importance {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__lines {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &__current-bar {
    height: 20px;
    background-color: $primary-blue;
  }

  &__updated-bar {
    height: 20px;
    background-color: $yellow-500;
  }

  &__omitted-bar {
    color: $neutral-color-300;
  }

  &__attr-info-section {
    width: auto;
    margin: 35px 0 20px 40px;
  }

  &__attr-info {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    font-size: 105%;
    color: $neutral-color-700;
    height: 50px;
  }

  &__attr-amount {
    font-size: 380%;
    margin-right: 15px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  &__attr-descr {
    font-size: 80%;
    color: $neutral-color-500;
    margin-top: 20px;
    div {
      margin-bottom: 20px;
    }
  }

  &__no-data__center {
    border: 1px solid $border-color;
    color: $neutral-color-500;
    font-size: 90%;
    text-align: center;
    margin: 20px 0 0;
    padding: 40px;
    p > span {
      color: $primary-blue;
    }
  }

  &__modal {

    &__descr {
      font-size: 80%;
      color: $neutral-color-700;
    }

    &__checkbox {
      position: relative;
      pointer-events: auto !important;
    }

    &__warning {
      height: auto;
      width: 100%;
      box-shadow: none;
      .flight-snackbar__actions {
        display: none;
      }
    }
  }


}

// Override MaterialUI Tooltip for Range Slider
.MuiTooltip-tooltip {
  background: $neutral-color-50 !important;
  border: 1px solid #ddd;
  color: black !important;
  padding: 5px 10px !important;
  font-size: 15px !important;
  font-weight: 100 !important;
}
