@import 'src/styles/variables';

.rv-xy-plot__axis__title text {
  font-size:16px;
}
.content-engagement-by-source-graph {
  margin: 30px 50px 0px 20px;
  .content-engagement-by-source-legend {
    width: 100%;
    margin-left: 40px;
    .rv-discrete-color-legend-item {
      float: left;
      font-size: 16px;
      &__color {
        &__path {
          stroke-width: 10px;
        }
      }
    }
  }

  .content-engagement-by-source-active-bar-hint {
    border-radius: 4px;
    background-color: map-get($neutral-colors, '900');
    padding: map-get($spacers, 'medium');
    color: map-get($primary-colors, 'white');
    font-size: 12px;
    font-weight: bold;
  }
}
