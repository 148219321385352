@import 'styles/variables';
$navbarBgColor: var(--navbarBgColor);
$appNameTextColor: var(--appNameTextColor);

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: 100vw;

  iframe {
    border: 1px solid #ddd;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  .app {
    width: 100%;
    height: 100%;

    .route-content,
    .route-content--without-sidebar {
      width: 100%;
      height: 100%;
    }

    .flight-nav__header {
      background-color: $navbarBgColor;
      z-index: 3;
    }

    .flight-nav__header__project__name {
      color: $appNameTextColor;
    }

    .flight-nav {
      .flight-nav__header__actionables {
        display: flex;
        flex: 1;

        .flight-dropdown__dropdown {
          right: 0;
          margin-top: $spacer-small;

          .redirect-project-settings {
            border-radius: 0;
            font-weight: $font-weight-regular;
          }
        }
      }

      .flight-nav__header__project__environment {
        transform: none;
      }
    }

    // Do not apply padding / margin inside this block.
    // Padding & Margin should be applied to individual page
    &__main {
      flex: 1;
      height: 100%;
    }
  }

  // This is needed to render above the ControlBar component
  .flight-modal-wrapper {
    z-index: 100;
  }

}
