@import 'styles/variables.scss';

.preview-push {
  position: initial;
  min-width: 300px;
  max-width: 300px;
  min-height: 570px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 20px;
  &__container {
    height: 545px;
    overflow: hidden;
    .dynamic-attr-wrapper, .dynamic-attr {
      border: 1px solid #CDD0D7;
      border-radius: 2px;
      display: inline-block;
      margin: 0px 3px;
      padding: 0px 3px;
      cursor: pointer;
      margin-bottom: 2px;
      word-break: break-all;
      .dynamic-attr__default-value {
        color: #0851DF;
        padding-left: 4px;
      }
    }

    &__image {
      width: 100%;
      margin: 20px 0;
    }

    &__preview-body {
      margin-top: 180px;
      display: flex;
      flex-direction: column;
      width: calc(100% - 65px);
      margin-right: 20px;
      margin-left: 20px;
      background-color: white;
      border-radius: $border-radius*2;
      padding: 12px;
      align-self: center;
      border: 1px solid $border-color;
      text-align: left;

      &__header {
        font-size: 10px;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &__title {
        font-weight: bold;
        margin-top: 5px;
        overflow: hidden;
        word-break: break-all;
      }

      &__body {
        width: 100%;
        font-size: 11px;
        margin: 5px 0 0 0;
        line-height: 1.3em;
        overflow: hidden;
        word-break: break-all;
      }

      &__button {
        align-self: center;
        margin: 10px;
      }

      &__placeholder {
        display: flex;
        justify-content: center;
        padding: 20px;
        font-weight: bold;
        color: $neutral-color-500;
      }

      &__your-app-icon {
        margin-right: 5px;
      }
    }
  }
}
