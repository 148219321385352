@import 'src/styles/variables';

.content-list-modal {
  .flight-modal__content {
    padding: 12px 24px 5px 24px !important;
    max-height: 500px !important;
    overflow: hidden !important;
  }
  width: 100%;
  &__header {
    display: flex;
    margin-top: -15px;
    margin-bottom: -15px;
    &-icon {
      width: 30px;
      margin-right: 10px;
    }

  }

  &__content {
    display: flex;
    flex-direction: row;
    min-height: 600px;
    &__error-checkbox {
      z-index: 100000 !important;
      margin-top: 5.5% !important;
      position: absolute !important;
      left: 75px !important;
    }
    &__error-banner {
      height:70px !important;
      width: 850px !important;
      z-index: 99999 !important;
      position: absolute !important;
      margin-top: 90px !important;
      .flight-button {
        margin-top: 42px;
      }
    }
    &__left {
      scroll-behavior: smooth !important;
      flex: 1;
      padding-left: 15px;
      padding-top: 20px;

      &__checkbox {
        position: relative;
        top: 25px;
        margin-left: 6px;
      }

      &__container {
        overflow-y: auto;
        max-height: 430px;
        padding-right: 10px;
        padding-top: 40px;

        &__item {
          width: 367px;
          border: 1.5px solid rgb(221, 222, 224);
          border-radius: 5px;
          padding: 15px;
          margin-bottom: 15px;
          background: white;
          word-break: break-all;
          cursor: pointer;
          z-index: 10;
          outline: none;
          margin-left: 30px;

          &__amplification-icon {
            margin-left: 10px;
            margin-bottom: -5px;
          }

          &:hover {
            background: rgba(218, 246, 255, 0.3);
            & .content-list-modal__content__left__container__item--edit {
              visibility: visible;
              opacity: 0.3;
            }
            & .content-list-modal__content__left__container__item--edit__shadow {
              visibility: visible;
              opacity: 0;
            }
          }

          &__loader {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: 20px;
          }
          &__loader-text {
            margin-left: 10px;
          }

          &--selected {
            margin-left: 30px;
            border: 2px solid $primary-blue;
            width: 367px;
            border-radius: 5px;
            padding: 15px;
            margin-bottom: 15px;
            background: white;
            word-break: break-all;
            cursor: pointer;
            &:hover {
              background: rgba(218, 246, 255, 0.3);
              & .content-list-modal__content__left__container__item--edit {
                visibility: visible;
                opacity: 0.3;
              }
              & .content-list-modal__content__left__container__item--edit__shadow {
                visibility: visible;
                opacity: 0;
              }
            }
          }

          &__header-icon {
            margin-right: 15px;
          }

          &__title {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: #1e2b4a;
            padding-bottom: 10px;
          }
          &__body {
            padding-bottom: 10px;
          }
          &__icon {
            margin-left: 90%;
          }
        }

        &__item--edit {
          float: right;
          cursor: pointer;
          visibility: hidden;
          &__shadow {
            margin-left: -25px;
            margin-bottom: -7px;
            cursor: pointer;
            border-radius: 50%;
          }
          &__pen {
            margin-left: -18px;
            margin-top: -10px;
            cursor: pointer;
          }

          &:hover {
            opacity: 1 !important;
            & .content-list-modal__content__left__container__item--edit__shadow {
              opacity: 1 !important;
            }
          }
        }
      }
    }
    .right {
      flex: 1;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 10px;
    }
  }

  .flight-tab {
    display: flex;
  
    ul {
      z-index: 10000;
      height: 35px;
      position: absolute;
      background-color: white;
      width: 48%;
      top: 85px;
      padding: 0;
      box-shadow: 0 2.3px 2px -2px gray;
    }
    &--list {
      height: 35px;
      display: flex;
      li {
        cursor: pointer;
        list-style: none;
        margin-right: 10px;
        padding: 0px 5px;
  
        &:active,
        &:focus {
          outline: none;
        }
      }
    }
    &--selected {
      height: 20px;
      border-bottom: 3px solid $primary-blue;
      transition: 3s opacity ease-out;
    
    }
  
    &__search {
      align-items: right;
      margin-left: auto;
    }
    .visible {
      visibility: visible;
    }
    .hidden {
      display: none;
    }
  }
  
}


