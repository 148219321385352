@import "src/styles/mixins";
@import "src/styles/variables";

.multi-step-selection {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: auto;

    &__block {
      display: flex;
      flex-direction: column;
      margin: 60px;
      @include lg {
        max-width: 70%;
      }
      @include xl {
        max-width: 800px;
      }

      &__title {
        font-size: 200%;
        @include xs {
          font-size: 150%;
        }
        font-weight: bold;
        margin: 0 0 60px 0;
      }

      &__list {
        display: grid;
        margin-bottom: 60px;
        @include sm {
          grid-template-columns: 1fr;
        }
        @include md {
          grid-template-columns: 1fr;
        }
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;

        &__error {
          color: red
        }

        &__empty {
          text-align: center;
        }
      }

      &__template-item {
        border: 1px solid #cccccc;
        padding: 0;
        display: flex;
        flex-direction: row;
        background-color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
        box-shadow: none;
        transition: box-shadow 400ms;


        &:hover {
          @include box-shadow;
          transition: box-shadow 400ms;
        }

        &:focus {
          outline: none;
          box-shadow: inset 0 0 10px rgba(68, 68, 68, 0.6);
        }

        &__image-box {
          display: flex;
          justify-content: center;
          align-items: center;

          &__img {
            width: 150px;
            height: 150px;
            padding: 20px;
          }
        }

        &__text-box {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 30px 30px 30px 0;
          @include xs {
            padding: 20px 20px 20px 0;
          }
          @include md {
            padding: 30px 30px 30px 0;
          }

          &__title {
            font-weight: bold;
            font-size: 160%;
            text-align: left;
          }

          &__description {
            text-align: left;
            font-size: 150%;
            line-height: 1.5em;
            margin-top: 20px;
            color: $neutral-color-800;
          }

          &__content {
            font-size: 120%;
            margin-top: 20px;
            color: $neutral-color-500;
          }

          &__elements {
            font-size: 120%;
            margin-top: 20px;
            display: flex;
            flex-direction: row;

            &__element {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-weight: bold;
              margin-right: 20px;

              img {
                margin-right: 10px;
              }
            }
          }
        }

      }
    }
  }
}
