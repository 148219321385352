@import "src/styles/variables";

.start {
  width: 100%;
  height: 100%;
  padding: 0;
  border-radius: 100%;
  cursor: grab;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $red-50;
  font-size: 16px;
  position: relative;

  &:active {
    cursor: grabbing;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
}
