@import 'src/styles/variables';

.filter-bar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: fit-content;

  &__text {
    font-size: $font-size-small;
    color: $primary-black;
    height: fit-content;
    margin-right: 10px;
  }

  &__filter {
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
    color: $primary-black;
    background-color: $primary-white;
    border: 1px solid $neutral-color-200;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 5px 10px 5px 0;
    cursor: pointer;

    &.selected {
      font-weight: $font-weight-bold;
      background-color: $blue-300;
      color: $primary-white;
    }
  }
}
