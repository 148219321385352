@import 'src/styles/variables';

.subcategory-column {
  position: relative;
  background-color: $primary-white;
  padding: 15px 0;
  border-radius: 5px;
  margin-bottom: 15px;
  overflow: hidden;

  &__subcategory {
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    color: $purple-600;
    margin-bottom: 15px;
  }

  &__template-card {
    box-shadow: 0 4px 2px -2px $neutral-color-200;
    margin-bottom: 20px;

    &--no-border {
      padding-bottom: 50px;
      pointer-events: none;
      border: none;
      &:hover, &:focus {
        border: none;
      }
    }

    .template-card__name {
      font-size: $font-size-small;
    }
  }

  &__ribbon {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    height: 30px;

    &__band {
      background-color: $purple-100;
      width: 50%;
      height: 12px;

      &.hidden {
        visibility: hidden;
      }
    }
    &__center {
      padding: 5px 12px;
      border-radius: 5px;
      background-color: $purple-100;
      font-size: $font-size-small;
      font-weight: $font-weight-bold;
    }
  }

  .sub-categorytile{
    float: left;
    width: 20%;
    .sub-categorytile-inner{
      position: relative;
      min-height: 163px;
      margin: 7px 20px 0 20px;
      background-color: #FFFFFF;
      border: 1px solid lightgray;
      cursor: pointer;
      user-select: none;
      &:hover{
        background-color: darken(white, 3%); 
      }
      &.activated{
        background-color: #000000;
        border: none;
        cursor: default;
        .meta{
          color: white;
        }
        &:hover{
          background-color: black; 
        }
      }
      .meta{
        position: absolute; 
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
        text-align: center;
        color: black;
        .sub-icon{
          height: 50px;
          margin-bottom: 5px;
        }
        .sub-title{
          text-align: center;
          font-weight: bold;
        }
      }
      .activation-status{
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 25px;
      }
    }
  }
  .templates-wrapper{
    float: left;
    width: 79%;
  }
}
