@import 'src/styles/variables';	

.library-card {	
  z-index: 0;	
  width: 156px;	
  height: 99px;
  border-radius: 5px;	
  background-repeat: no-repeat;	
  cursor: pointer;	
  background-size: 156px;	
  &:hover {	
    background-color: #ff4f00;	
  }	

  &__img {	
    transition: 0.5s ease;	
    pointer-events: none;	
    position: relative;	
    &__text {	
      font-weight: 800;	
      font-size: 19px;	
      opacity: 0.9;	
      line-height: 26px;	
      position: absolute;	
      text-shadow: 0px 0.5px 0.5px rgba(0, 0, 0, 0.5);	
      color: white;	
      top: 47px;	
      white-space: normal;	
      transform: translate(-50%, -50%);	
      left: calc(100% - 78px);	
      width: 100px;	
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {	
        opacity: 0;	
      }	
    }	
  }	
}	

.overlay {	
  background-color: rgba(0, 0, 0, 0.5);	
  height: 100px;	
  margin-top: -100px;	
  transition: 0.5s ease;	
  border-radius: 5px;	
  &--selected {	
    background-color: #ff4f00;	
  }	

  cursor: pointer;	
  &:hover {	
    background-color: #ff4f00;	
  }	
}
