@import 'styles/variables';

.modal__content {
  height: 100%;
}

// override xlarge modal until supported in design system
.flight-modal-wrapper .flight-modal--large.xlarge {
  min-height: 850px;
  .content {
    max-height: 650px;
  }
  .flight-modal__content {
    height: 620px;
    max-height: 620px;
    overflow-y: scroll;
    .modal {
      height: 100%;
    }
  }
}

.audience-edit-modal {
  width: 100%;
  height: 100%;
  .tab {
    height: 50px;
    padding-bottom: 10px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    &__content {
      width: 400px;
      position: relative;
      &-links {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        a {
          cursor: pointer;
          border: none;
          width: 200px;
          color: black;
          font-size: 16px;
          text-align: center;
          &:active {
            outline: 0;
          }
          &.selected {
            animation: all 1s ease;
            font-weight: 600;
          }
          text-decoration: none;
          padding: 15px;
        }
      }
      .underline {
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all 0.5s ease;
        width: 200px;
        height: 1px;
        background: $primary-blue;
        border: 1px solid $primary-blue;
        &.left {
          transform: translateX(0px);
        }
        &.right {
          color: blue;
          transform: translateX(200px);
        }
      }
    }
  }
}
