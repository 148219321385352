.snackbar {
  width: 100%;
  position: absolute;
  top: 0;
  text-align: center;
  z-index: 999;
  height: auto;

  .flight-snackbar {
    height: auto !important;

    &__content {
      margin-right: 70px;
    }
  }
}
