@import 'src/styles/variables';

.amplified-push-preview {
  &__push-preview {
    margin-left: $spacer-medium;
    margin-right: $spacer-medium;
    position: relative;

    &__hoverable {
      margin-left: -19px;
      border: 1px solid transparent;
      position: absolute;
      height: calc(100% + 26px);
      top: -12px;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      width: 114.7%;
      &:hover {
        border: 1px solid $blue-300;
        background-clip: content-box;
      }
    }

    &__icon {
      margin-left: -$spacer-large + 5;
    }

    &__title {
      font-size: $font-size-xsmall;
      color: $purple-300;
      float: right;
      margin-right: 49.5%;
      margin-top: 1%;
    }

    &__message {
      margin-top: $spacer-large;
      font-weight: $font-weight-bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__add-amplification {
      margin-top: $spacer-large * 2;
      margin-bottom: $spacer-large;
      font-weight: $font-weight-bold;
      font-size: $font-size-small;
      color: $primary-blue;
      padding: 10px;
      cursor: pointer;
      text-align: center;
      &:hover,
      &:focus {
        background-color: #f2f3f5;
        border-radius: 24px;
      }
    }
  }
}

.amplification-bar {
  &__amplification {
    background-color: $purple-50;
    height: 51px;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: $spacer-large;
    &__items {
      font-size: $font-size-medium;
      &__icon {
        float: left;
        margin-left: $spacer-large;
        margin-top: $spacer-medium * 2;
      }

      &__primary-text {
        float: left;
        margin-top: $spacer-medium * 2;
        margin-left: $spacer-large;
        font-size: $font-size-small;
      }

      &__remove-text {
        float: right;
        margin-top: 6px;
        margin-right: $spacer-large * 2;
        color: $blue-500;
        font-size: $font-size-small;
        background-color: $purple-50 !important;
        &:hover {
          background-color: #f2f3f5 !important;
        }
      }
    }
  }
}
