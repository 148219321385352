@import "src/styles/variables";

.web-page {
  width: 100%;
  height: auto;
  border-radius: $border-radius;
  cursor: grab;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: $spacer-2xlarge;
  flex-direction: column;
  position: relative;
  background-color: $primary-white;
  border-radius: $border-radius*2;
  border: $border-width * 2 solid $border-color;

  &__header {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &__left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
    &__icon {
      margin-right: 15px;
      width: 30px;
      height: 30px;
    }
    &__title {
      color: $neutral-color-600;
      font-weight: bold;
    }
  }

  &__body {
    width: 100%;
    overflow: hidden;
  }

  &:hover {
    border: $border-width * 2 solid $primary-blue;
  }

  &:active {
    cursor: grabbing;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }

  &.not-clickable {
    &:hover,
    &:focus {
      box-shadow: none;
      border: $border-width * 2 solid $border-color;
    }
  }

  &__remove {
    position: absolute;
    top: -30px;
    right: 0;
    padding: 5px 10px;
    text-decoration: none;
    cursor: default;
    color: $neutral-color-base;
    &:hover {
      color: $error-color;
      background-color: $neutral-color-50;
      cursor: pointer;
      border-radius: $border-radius;
    }
  }
}
