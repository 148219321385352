.rv-xy-plot__axis__title text {
  font-size:16px;
}
.experience-performance-container {
  margin: 30px 20px 0px 20px;
  font-size: 14px !important;
  .experience-performance-legend-container {
    width: 100%;
    margin-left: 40px;
    .rv-discrete-color-legend-item {
      float: left;
      font-size: 16px;
    }
  }
}

.crossHair_container {
  .rv-crosshair__inner {
    width: 200px;
    padding: 10px;
    border-radius: 5px;
    background-color: #051336;
    color: #ffffff;
    .crossHair_col {
      clear: both;
      .left-color {
        width: 10px;
        height: 10px;
        float: left;
        margin: 4px 5px 0px 0px;
        border-radius: 6px;
      }
      .left-color-ta {
        background-color: #7C4DFF;
      }
      .left-color-pd {
        background-color: #3AC6FF;
      }
      .left-color-op {
        background-color: #16D892;
      }
      .left-color-coc {
        background-color: #EC2758;
      }
      &__name {
        float: left;
      }
      &__value {
        float: right;
      }
    }
  }
}
