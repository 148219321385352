@import 'src/styles/mixins';

.full-page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 999;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: 0.4s ease;

  &.hidden {
    transform: translate(0, 100vh);
  }

  &.visible {
    transform: translate(0, 0vh);
  }
}
