@import 'styles/variables.scss';

.labels-modal {
  padding: 0;
  margin: 0;
  top: -20px;

  &__content {
    display: flex;
    flex-direction: column;
    min-height: 250px;
    height: auto;
    width: 100%;
    justify-content: center;

    &__title {
      font-weight: bold;
      font-size: 120%;
    }

    & .flight-select-searchable,
    & .flight-text-input-wrapper {
      width: 100% !important;
    }
  }

  &__error {
    color: red;
    text-align: center;
    padding: 20px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      margin-left: $spacer-large;
    }
  }

  .flight-modal__content {
    overflow: visible !important;
  }
}
