@import 'src/styles/variables';

.content-form {
  &__content {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;

    &__heading {
      font-weight: bold;
      margin: 10px 0 10px 0;
    }

    &__footer {
      &__checkbox {
        padding-top: 20px;
        position: relative;
        float: left;
      }

      &__icon {
        height: 15px;
        width: 15px;
        float: right;
        margin-top: 24px;
        margin-left: 10px;
      }
    }

    &__input {
      margin-top: 10px;
    }
  }
}
