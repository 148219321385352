@import 'src/styles/variables';

.minicard {
  width: 100%;
  &__icon {
    height: 17.25px;
    width: 17.25px;
    float: right;
    padding: $spacer-large;
    fill: $blue-300;

    &--error {
      fill: red;
      margin-right: $spacer-small;
      padding: $spacer-medium;
    }
  }

  &__locked-icon {
    margin-left: 240px;
    margin-bottom: -40px;
  }

  &__collapse {
    width: 108%;
    border-bottom-left-radius: 6.4px;
    border-bottom-right-radius: 6.4px;
    margin-left: -10.5px;
    margin-bottom: -15px;
    background-color: $neutral-color-100;
    height: 24px;
    display: flex;
    svg {
      margin-top: $spacer-medium;
    }
    &:hover {
      background-color: $blue-300;
      transition: all 300ms;
      svg {
        fill: $primary-white;
      }
    }

    &__polygon {
      align-content: center;
      margin-left: auto;
      margin-right: auto;
      fill: $neutral-color-600;
      &--transform {
        transform: rotate(360deg);
        align-content: center;
        margin-left: auto;
        margin-right: auto;
        fill: $neutral-color-600;
      }
    }
  }

  &__content {
    min-height: 60px;
    min-width: 250px;
    width: auto;
    background: $primary-white;
    &:hover {
      border: 1px solid $blue-300;
    }
    border: 1px solid #b4b8c3;
    border-radius: 8px;
    padding: 15px 10px;
    cursor: pointer;
    > header {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      .title {
        &--content {
          font-size: $font-size-xsmall;
          color: $teal-600;
        }
        &--push {
          font-size: $font-size-xsmall;
          color: $purple-300;
        }
      }
      img {
        margin-left: 3px;
        margin-right: 10px;
      }
    }
    > section {
      padding: 5px;
      p {
        margin: 0;
        padding: 0;
      }
    }
    &--hoverable {
      &:hover {
        border: 1px solid $blue-300;
      }
    }
    &--error {
      border: 1px solid red;
      &:hover {
        border: 1px solid red;
      }
    }
    &--not-clickable {
      cursor: default;
    }
  }
}
