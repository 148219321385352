@import 'src/styles/variables';

.delay-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &__button {
    display: flex;
    flex-direction: column;
    padding: $spacer-2xlarge;
    background-color: $yellow-100;
    border-radius: $border-radius*2;
    border: $border-width * 2 solid $yellow-100;
    width: auto;
    cursor: pointer;
    margin: 0 $spacer-2xlarge;
    box-sizing: border-box;
    box-shadow: none;
    transition: all 300ms;

    &.error {
      border: $border-width * 2 solid rgb(240, 117, 79);
      box-sizing: border-box;
    }

    &:hover, &:focus {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      transition: all 300ms;
    }

    &.not-clickable {
      cursor: default;
      &:hover, &:focus {
        box-shadow: none;
      }
    }
  }

  &__card {
    position: initial !important;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    padding-top: 10px;
  }

  &__description {
    color: $neutral-color-600;
    font-size: 90%;
    margin-top: 10px;
    white-space: nowrap;
  }

  &__icon {
    display: flex;
    position: relative;
    align-self: center;
    margin: 10px 0 30px 0;
  }

  &__title {
    font-weight: bold;
  }

  &__arrow {
    margin-top: 80px;
    margin-left: -20px;
  }
}
