@import 'src/styles/mixins';
@import 'src/styles/variables';

.templated-experiences-edit {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  background-color: $comp-background-color;

  &__performance {
    display: flex;
    justify-content: center;
    padding: 0px 20px;
    &__container {
      display: flex;
      width: 100%;
      max-width: 1100px;
      align-items: center;
      padding: $spacer-2xlarge*4;
      flex-direction: column;
      .analytics-cards-container {
        display: flex;
        width: 100%;
        > div {
          flex: 1;
          margin-right: 25px;
        }
        > div:last-child {
          margin-right: 0;
        }
      }
      .analytics-chart-container {
        margin-top: 35px;
        width: 100%;
        margin-bottom: 35px;
        .chart-item {
          margin-bottom: 50px;
        }
        .no-data {
          h2 { margin: 0; padding: 0; font-weight: bold;}
          p { font-size: 18px }
          border: 1px solid #ddd;
          background: white;
          height: 500px;
          padding: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  &__main {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;

    &__flow {
      display: flex;
      width: auto;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: $spacer-2xlarge*5;
      box-sizing: border-box;
    }

    &__progress {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex: 1;
      padding: $spacer-2xlarge*4;
      width: 100%;
      height: 100%;
    }

    &__error {
      color: red;
      text-align: center;
      padding: 60px;
    }

    &__empty {
      text-align: center;
      padding: 60px;
    }
  }

  &__finish-icon {
    margin: 75px 0 0 $spacer-2xlarge;
  }

  &__header {
    // Cusom Tab
    .tab {
      background: white;
      width: 100%;
      position: relative;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      &__content {
        position: relative;
        width: 300px;
        &-link {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          width: 100%;
          a {
            cursor: pointer;
            border: none;
            width: 150px;
            color: black;
            font-size: 16px;
            text-align: center;
            &:active {
              outline: 0;
            }
            &.selected {
              animation: all 1s ease;
              font-weight: 600;
            }
            text-decoration: none;
            padding: 15px;
          }
        }
      }

      .underline {
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all 0.5s ease;
        width: 150px;
        height: 1px;
        background: $primary-blue;
        border: 1px solid $primary-blue;
        &.left {
          transform: translateX(0px);
        }
        &.right {
          color: blue;
          transform: translateX(150px);
        }
      }
    }
  }

  .border {
    border: 1px solid #ddd;
    box-sizing: border-box;
  }
}
