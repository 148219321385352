@import 'src/styles/variables';

.notifications {
  cursor: pointer;
  &__icon {
    margin-right: 10px;
    padding: 5px;
  }

  &__circle {
    cursor: pointer;
    height: 15px;
    width: 15px;
    background-color: $secondary-red;
    border-radius: 50%;
    display: inline-block;
    margin-left: -29px;
    margin-top: 6px;
    font-size: 13px;
    position: absolute;
    color: white;
    text-align: center;
    font: bold 8.5px/16px $font-family-base;
    min-width: 16px;
  }

}
