@import 'styles/variables.scss';

.multiaction-push-preview {
  position: initial;
  min-height: 91px;
  margin-top: 20px;
  width: 100%;

  &__preview-body {
    display: flex;
    flex-direction: column;
    width: calc(100% - 25px);
    background-color: white;
    border-radius: $border-radius * 2;
    padding: 12px;
    align-self: center;
    border: 1px solid $purple-300;
    text-align: left;

    &__empty-state {
      border: none;
      &__icon {
        display: block;
        margin: auto;
        margin-top: -$spacer-medium;
        margin-bottom: $spacer-large;
        &__body {
          color: $neutral-color-600;
          font-size: $font-size-small;
          text-align: center;
        }
      }
    }

    &__header {
      font-size: 10px;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__title {
      font-weight: bold;
      overflow: hidden;
      margin-top: -10px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
    }

    &__body {
      width: 100%;
      font-size: 11px;
      margin: 5px 0 0 0;
      line-height: 1.3em;
      max-height: 55px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }


    &__your-app-icon {
      margin-right: 5px;
    }
  }
}
