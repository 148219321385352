.rv-xy-plot__axis__title text {
  font-size:16px;
}
.multi-instance-container {
  margin: 30px 50px 0px 20px;
  .multi-instance-legend-container {
    width: 100%;
    margin-left: 40px;
    .rv-discrete-color-legend-item {
      float: left;
      font-size: 16px;
    }
  }
}

.crossHair_container {
  .rv-crosshair__inner {
    width: 200px;
    padding: 10px;
    border-radius: 5px;
    background-color: #051336;
    color: #ffffff;
    .crossHair_col {
      clear: both;
      .left-color {
        width: 10px;
        height: 10px;
        float: left;
        margin: 4px 5px 0px 0px;
        border-radius: 6px;
      }
      .left-color-first {
        background-color: #A999FF;
      }
      .left-color-second {
        background-color: #7CB4FF;
      }
      .left-color-third {
        background-color: #72DAFF;
      }
      .left-color-fourth {
        background-color: #51E7BB;
      }
      &__name {
        float: left;
      }
      &__value {
        float: right;
      }
    }
  }
}
