@import 'styles/variables.scss';

.folders-modal {
  padding: 0;
  margin: 0;
  top: -20px;

  &__search-title {
    font-weight: bold;
    margin-bottom: 5px;
  }

  &__selected {
    font-size: 90%;
    color: $neutral-color-500;
    margin-bottom: 10px;
  }

  &__folder-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
  }

  &__item {
    display: flex;
    background-color: transparent;
    border: none;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: $spacer-large $spacer-large $spacer-large 0;
    &:hover {
      background-color: $neutral-color-50;
    }
    &.selected {
      outline: none;
      font-weight: bold;
      background-color: $blue-50;
    }

    &__subfolder {
      background-color: transparent;
      border: none;
      padding: $spacer-large;
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      text-align: left;
      align-items: center;
      cursor: pointer;
      position: relative;
      &:hover {
        background-color: $neutral-color-50;
      }
      &.selected {
        outline: none;
        font-weight: bold;
        background-color: $blue-50;
      }
    }
  }

  &__new-folder {
    display: inline-flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    &__input {
      width: 100%;
      position: relative;
      input {
        height: 30px;
        background-color: transparent;
      }
    }
    &__icon {
      position: relative;
      padding-left: 10px;
      height: 24px;
    }
  }

  &__icon {
    margin-right: 10px;
    margin-left: $spacer-large*3;
    &.disabled {
      opacity: 0.5;
    }
  }

  &__button-arrow {
    background-color: transparent;
    border: none;
    padding: $spacer-large;
    position: absolute;
    cursor: pointer;
    z-index: 999;
  }

  &__arrow {
    position: relative;
    transform: rotate(90deg);
    &.disabled {
      opacity: 0.3;
    }
    &.expanded {
      transform: rotate(180deg);
    }
  }

  &__rule-list-container {
    overflow-y: auto;
    margin-top: 20px;
    padding-right: 20px;
    max-height: 300px;
    position: relative;

    &__infinite-scroll {
      max-height: 300px;
      position: relative;
    }

    &__loader {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 20px;
    }

    &__loader-text {
      margin-left: 10px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      margin-left: $spacer-large;
    }
  }

  &__error-message {
    color: $error-color;
    text-align: center;
    padding: $spacer-large;
  }

}
