@import 'src/styles/variables';

.mini-card-content {
  &__not-clickable {
    cursor: default;
    pointer-events: none;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }

  &__supported-content {
    &__title {
      font-weight: $font-weight-bold;
      margin-bottom: $spacer-large * 2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__no-ctnt {
    margin-bottom: $spacer-large;
    &__icon {
      display: block;
      margin: auto;
      margin-top: $spacer-large;
      margin-bottom: $spacer-large;
      &__body {
        color: $neutral-color-600;
        font-size: $font-size-small;
        text-align: center;
      }
    }
  }

  &__unsupported-content {
    position: relative;
    &__hoverable {
      border: 1px solid red;
      position: absolute;
      height: 140px;
      z-index: 999999;
      width: 277.5px;
      border-radius: 8px;
      top: 20.6%;
      margin-left: -28%;
      &:hover {
        border: 1px solid $blue-300;
        background-clip: content-box;
      }
    }
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    margin-right: 9px;
    margin-top: $spacer-large;
    align-self: center;
    text-align: left;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 201.5px;

    &__header {
      font-weight: $font-weight-bold;
    }
    &__body {
      color: $neutral-color-600;
      margin-top: $spacer-large;
      font-size: $font-size-small;
      margin-bottom: $spacer-large;
    }
    &__icon {
      position: relative;
      top: 2px;
      margin-left: $spacer-medium;
    }
  }
}
