@import 'styles/variables';
@import 'styles/mixins';

.audience-builder {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .container {
    padding: 20px;
  }

  .header {
    padding: 20px 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    p {
      font-size: 22px;
    }
    .cta {
      button:first-child {
        margin-right: 10px;
      }
    }
  }

  .tab {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #ddd;
    &__content {
      width: 440px;
      position: relative;
      &-links {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        a {
          cursor: pointer;
          border: none;
          width: 220px;
          color: black;
          font-size: 16px;
          text-align: center;
          position: relative;
          display: flex;
          @include center-vh;
          &:active {
            outline: 0;
          }
          &.selected {
            animation: all 1s ease;
            font-weight: 600;
          }
          .tab-icon {
            display: block;
            svg {
              margin-left: 5px;
              fill: red;
              width: 20px;
              height: 20px;
            }
          }
          text-decoration: none;
          padding: 15px;
        }
      }
      .underline {
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all 0.5s ease;
        width: 220px;
        height: 1px;
        background: $primary-blue;
        border: 1px solid $primary-blue;
        &.left {
          transform: translateX(0px);
        }
        &.right {
          color: blue;
          transform: translateX(220px);
        }
      }
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    padding: 0px 40px;
    flex: 1;
    .iframe-container {
      height: 100%;
    }
  }

}

.preferred-audience-notify-modal {
  p {
    margin: 0;
    line-height: 22px;
    margin-bottom: 25px;
  }
}
