

.no-access {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  .header {
    padding: 10px;
    background: rgb(218, 62, 62);
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 15px;
  }
  .jwtinject{
    display: block;
    min-width: 400px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    line-height: 30px;
    padding: 0 10px;
  }
  .btn.submit{
    touch-callout: none;
    user-select:none;
    cursor: pointer;
    background-color: #1162A4;
    color: white;
    text-align: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    line-height: 30px;
    &:hover{
      background-color: #0d4f84;
    }
  }
}