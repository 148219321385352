@import 'styles/variables.scss';

.groups-modal {
  padding: 0;
  margin: 0;
  top: -20px;

  &__search-title {
    font-weight: bold;
    margin-bottom: 5px;
  }

  &__selected {
    font-size: 90%;
    color: $neutral-color-500;
    margin-bottom: 10px;
  }

  &__item {
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    line-height: 3em;
    cursor: pointer;
  }

  &__rule-list-container {
    overflow-y: auto;
    margin-top: 20px;
    padding-right: 20px;
    max-height: 300px;
    position: relative;

    &__infinite-scroll {
      max-height: 300px;
      position: relative;
    }

    &__loader {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 20px;
    }

    &__loader-text {
      margin-left: 10px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      margin-left: $spacer-large;
    }
  }

}
