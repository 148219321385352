@import 'styles/variables.scss';

.ao-demo-analytics-performance-optimized-chart {
  background: white;
  border: 1px solid #ddd;
  padding: 50px;

  .custom-tooltip {
    background: $primary-black;
    color: white;
    font-size: 13px;
    padding: 10px;
    width: 150px;
    .row {
      display: flex;
      justify-content: space-between;
      color: map-get($neutral-colors, "500");
      p {
        margin: 0;
        padding: 0;
      }
      &.highlight {
        color: white;
      }
    }
  }

  &__main {
    flex: 1;
    height: 500px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;

    .top {
      .graph-button {
        svg {
          fill: $primary-blue;
        }
        color: $primary-blue;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      p {
        margin: 0;
        padding: 0;
        &.headline {
          font-size: 23px;
        }
      }
    }

    // Use this to display status
    .status {
      position: absolute;
      width: 100%;
      top: 30px;
      .flight-snackbar {
        margin-top: 15px;
        width: 97%;
      }
    }

    .chart {
      width: 100%;
      height: 100%;
      margin-top: 20px;
      .label {
        opacity: 0.7;
        text-anchor: middle;
      }
      .reference-line {
        opacity: 0.5;
        font-size: 14px;
        font-weight: bold;
      }
      .line {
        stroke-width: 3px;
      }
    }
  }

  &__control {
    position: relative;
    margin-top: 35px;
    margin-left: 80px;
    .range-slider {
      margin-bottom: 45px;
    }
    .button-actions {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      button {
        margin-left: 10px;
      }
      .remove-button {
        background: $secondary-red;
        &:hover {
          background: map-get($reds, "600");
        }
        &:active:enabled {
          background: map-get($reds, "700");
        }
      }
    }
  }

}

// Override MaterialUI Tooltip for Range Slider
.MuiTooltip-tooltip {
  background: $neutral-color-50 !important;
  border: 1px solid #ddd;
  color: black !important;
  padding: 5px 10px !important;
  font-size: 15px !important;
  font-weight: 100 !important;
}
