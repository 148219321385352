@import 'src/styles/variables';

.templated-experiences-index {

  &__main {
    & th {
      background-color: $neutral-color-50;
    }
  }

  &__error-message {
    color: $error-color;
    padding: $spacer-2xlarge;
    text-align: center;
  }

  &__empty {
    color: $neutral-color-500;
    padding: $spacer-2xlarge;
    text-align: center;
  }

  &__buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    &__dropdown button {
      padding: 24px;
    }
  }

  &__table-actions {
    background-color: $neutral-color-50;
    padding: $spacer-large 0;
    display: flex;
    width: 100%;
    position: relative;
    border-bottom: 1px solid $border-color;
    &__action {
      margin-left: $spacer-medium*4;
      color: $primary-blue;
      font-weight: bold;
      text-decoration: none;
      &:hover {
        opacity: 0.8;
      }
      &.counter {
        //margin-left: 0;
        font-weight: normal;
        color: $neutral-color-700;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: 40px 80px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 40px;
  }

  &__filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 $spacer-xlarge 0;

    &__search-container {
      display: flex;
      flex-direction: row;
      &__filter {
        margin-left: $spacer-xlarge;
        width: auto !important;
        &>div>.flight-dropdown__dropdown>div{
          min-width: 150px !important;
        }
      }
    }

    &__search {
      max-width: 250px !important;
      margin-right: $spacer-xlarge;
    }

    &__button {
      border-radius: $border-radius !important;
      border: none !important;
      box-shadow: none !important;
      background-color: $neutral-color-50;
      margin-right: $spacer-xlarge;
      white-space: nowrap;

      &__label {
        background-color: $primary-blue;
        color: $primary-white;
        padding: 2px 6px;
        border-radius: $border-radius*4 !important;
        font-size: 80%;
        margin-left: 5px;
      }

      &.active {
        background-color: $blue-50 !important;
      }
    }

    &__button-clear {
      white-space: nowrap;
    }
  }

  &__create-button {
    padding: 0 20px;
    margin-left: $spacer-2xlarge;
  }

  &__title {
    font-size: 150%;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__subtitle {
    color: $neutral-color-700;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow: hidden;
  }

  &__icon {
    margin-right: 10px;
    color: $neutral-color-600;
  }

  &__path-icon {
    margin-right: 5px;
    fill: $neutral-color-200;
    width: 15px;
  }

  &__instance-name {
    width: 100%;
  }

  &__path {
    color: $blue-300;
    font-size: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    flex-wrap: wrap;
    & span {
      margin-right: 5px;
    }
  }

  &__status {
    width: fit-content;
    padding: 2px 5px;
    border-radius: $border-radius;
    text-transform: uppercase;

    &.draft {
      color: $neutral-color-700;
      background-color: lighten($neutral-color-700, 50%);
    }
    &.active {
      color: $green-700;
      background-color: lighten($green-400, 50%);
    }
    &.inactive {
      color: $red-500;
      background-color: lighten($red-600, 50%);
    }
    &.scheduled {
      color: $teal-700;
      background-color: lighten($teal-500, 50%);
    }
  }

  &__labels {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    font-size: 80%;
    width: auto;
    &__label {
      background-color: $purple-50;
      padding: $spacer-small/2 $spacer-large;
      margin: 0 $spacer-base $spacer-base 0;
      border-radius: $border-radius * 4;
    }
    &__text {
      width: 100%;
      font-weight: bold;
    }
  }

  &__shedule-descr {
    font-size: 90%;
    color: $neutral-color-500;
  }

  &__user-timezone {
    color: $neutral-color-300;

    hr {
      border-color: $neutral-color-300;
      border-width: 1px;
    }
  }

  &__tooltip {

  }

  &__dropdown {
    z-index: unset;

    .flight-overflow-menu {
      z-index: unset;
    }

    .flight-overflow-menu__dropdown-list--opened {
      z-index: 1;
    }
  }

}
