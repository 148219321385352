@import 'src/styles/variables';

.search-bar-page {
  position: relative;
  width: fit-content;

  &__icon {
    position: absolute;
    top: 30%;
    right: -55px;
    transform: translateX(-20px);
    background-color: none;

    &:hover {
      cursor: pointer;
    }
  }

  .flight-text-input-wrapper {
    .flight-text-input {
      &__input {
        padding: 0 0 0 15px;
        padding-right: 35px;
        transition: all 0.2s ease-in-out;
        font-size: $font-size-medium;
        border-radius: 5px 5px 0 0;

        &:focus {
          background-color: $neutral-color-100;
        }
      }
      &__border {
        width: calc(100% + 50px);
      }
    }
  }
}
