@import 'src/styles/variables';

.side-panel {
  visibility: visible;
  transition: visibility 0s linear 0s;

  &.hidden {
    visibility: hidden;
    transition: visibility 0s linear 0.1s;
    .inner-overlay{
      opacity: 0;
    }
    .inner-wrap{
      transform: translate3d(100%,0,0);
    }
  }
  .inner-overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    opacity: 1;
    transition: opacity 0.1s linear;
    background-color: rgba(black,0.5);
  }
  .inner-wrap{
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 530px;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    transform: translate3d(0,0,0);
    transition: transform 0.1s linear;
    z-index: 3;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;
    border-bottom: 1px solid $neutral-color-100;
  }

  &__title {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    color: $primary-black;
  }

  &__close {
    cursor: pointer;
  }

  &__content {
    flex: 1 1 auto;
    padding: 20px;
    overflow: auto;
  }

  &__footer {
    flex-shrink: 0;
    border-top: 1px solid $neutral-color-100;
    padding: 20px;
  }
}
