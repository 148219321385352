@import 'src/styles/variables';

.incomplete-counter {
  border: none;
  padding: 10px 35px 10px 25px;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: $neutral-color-500;
  justify-content: flex-end;
  background: #F7FAFC;
  cursor: pointer;
  position: absolute;
  right: 0;
  margin-top: 45px;
  z-index: 2;
  border-radius: 4px 0 4px 4px;

  svg {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    fill: $neutral-color-500;
  }

  &.error {
    color: $secondary-red;
    fill: $secondary-red;
    svg {
      fill: $secondary-red;
    }
  }

}
