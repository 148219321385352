@import 'styles/variables.scss';

.multi-push-preview {
  margin-left: $spacer-medium;
  margin-right: $spacer-medium;

  &--locked {
    pointer-events: none;
  }

  &__message {
    font-weight: $font-weight-bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__subject {
    margin-top: 12px;
    overflow: hidden;
    &__leads {
      font-size: $font-size-small;
      color: $neutral-color-900;
    }
  }
  &__push-type {
    color: $neutral-color-900;
    font-size: $font-size-small;
    &__type-svg {
      position: relative;
      top: 6px;
      fill: $neutral-color-900;
      margin-right: $spacer-small;
      margin-left: $spacer-small;

      &__type-content {
        color: $teal-600 !important;
      }
    }

    &__type-svg-link {
      position: relative;
      top: 2px;
      fill: $neutral-color-900;
      margin-right: $spacer-small;
      margin-left: $spacer-small;
      &__type-content {
        color: $teal-600 !important;
      }
    }

    &__message {
      color: $teal-600;
    }

    &__link {
      margin-top: $spacer-medium;
      font-size: $font-size-small;
    }

    &__push-content {
      margin-top: $spacer-2xlarge;
    }
  }
}
