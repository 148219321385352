@import 'src/styles/mixins';
@import 'src/styles/variables';

.templated-experiences-performance {
  padding: $spacer-2xlarge $spacer-2xlarge * 8;

  &__date-range-picker {
    // placeholder styles
    margin-bottom: $spacer-2xlarge;
  }

  &__cards {
    @include outline-none;
    display: none;

    .flight-card {
      flex-grow: 1;
      min-width: 200px;
      box-sizing: border-box;
      height: 108px;
      border: $border-width solid map-get($neutral-colors, '100');
      border-radius: 0;
      padding: $spacer-2xlarge;
      background-color: map-get($neutral-colors, '0');

      &:not(:last-child) {
        margin-right: $spacer-2xlarge * 2;
      }

      &__header {
        font-weight: normal;
      }

      &__body {
        margin-top: $spacer-medium;
        font-size: 32px;
        line-height: 40px;
      }
    }
  }

  &__experience-funnel, &__experience-performance,
  &__experience-flow, &__content-engagement-by-source,
  &__optimized-audience, &__content-engagements,
  &__content-engagements-over-time {
    background: map-get($neutral-colors, '0');
    border: $border-width solid map-get($neutral-colors, '100');
    box-sizing: border-box;
    padding: $spacer-large * 2 $spacer-xlarge * 2;
    margin-top: $spacer-2xlarge * 2;
    min-width: 680px;
  }

  &__experience-funnel, &__experience-flow, &__content-engagement-by-source {
    padding-bottom: 0px;
    &__title {
      font-weight: bold;
      font-size: 18px;
    }
    .experience-funnel-container {
      position: relative;
    }
    .hint-label {
      list-style-type: none;
      display: inline-flex;
      float: left;
      &__text {
        width: 50px;
        background-color: white;
        margin: 0px;
        padding: 10px;
        border: 1px solid #4992FD;
        border-right-width: 0px;
        z-index: 10;
        color: #000000;
        text-align: center;
      }
      &__arrow {
        height: 29px;
        width: 29px;
        border: 1px solid #4992FD;
        border-left-width: 0px;
        border-bottom-width: 0px;
        margin: 6px 0px 0px -15px;
        background-color: white;
        font-weight: bold;
        transform: rotate(45deg);
      }
    }
  }
  &__experience-performance {
    &__title {
      font-weight: bold;
      font-size: 18px;
      display: inline-block;
    }
    .flight-overflow-menu {
      display: inline-block;
      float: right;
    }
  }
  &__content-engagements__title {
    font-weight: bold;
    font-size: 18px;
  }
  &__content-engagements-over-time {
    &__title {
      font-weight: bold;
      font-size: 18px;
      display: inline-block;
    }
    .flight-overflow-menu {
      display: inline-block;
      float: right;
    }
    &__tab {
      display: inline-block;
      float: right;
      padding: 10px;
      &__views, &__clicks {
        float: left;
        margin-right: 10px;
        cursor: pointer;
      }
      .highlight-tab {
        border-bottom: 1px solid #0951DF;
        color: #0951DF;
      }
    }
  }
}
