@import 'styles/variables';

.audience-searchable-modal {
  .flight-modal__content {
    overflow: hidden !important;
  }
  &__header {
    padding: 5px 0;
  }

  &__main {
    display: flex;
    max-height: 450px;
    min-height: 450px;
  }

  &__right {
    flex: 1;
    background: #F2F3F5;

    &__audience-preview {
      padding: 30px;
      &__name {
        font-size: 18px;
        font-weight: 600;
        word-break: break-all;
      }
      &__description {
        &__audience-section {
          &__condition {
            font-size: 16px;
            font-style: italic;
            margin: 25px 0px;
          }
          &__row {
            &__predicate {
              color: #47526D;
            }
            display: flex;
            margin-bottom: 8px;
            font-size: 16px;
            &__index {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  &__left {
    flex: 1;
    padding-right: 15px;
    &__item {
      svg {
        fill: #697187;
        margin-right: 10px;
      }
      &:hover {
        background: #EEF5FF;
      }
      &:focus,&:active {
        box-shadow: none;
      }
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border: 1.5px solid rgb(221, 222, 224);
      border-radius: 5px;
      padding: 15px;
      margin-bottom: 10px;
      background: white;
      font-weight: bold;
      font-size: 16px;
      word-break: break-all;
      cursor: pointer;
      &--selected {
        box-sizing: border-box;
        border: 2px solid #274BE3;
        background: #EEF5FF;
      }
    }
  }

  &__rule-list-container {
    overflow-y: auto;
    margin-top: 20px;
    padding-right: 20px;
    max-height: 370px;
    position: relative;
    &__infinite-scroll {
      max-height: 370px;
      position: relative;
    }
    &__loader {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 20px;
    }
    &__loader-text {
      margin-left: 10px;
    }
  }

  &__preview-container {
    overflow-y: auto;
    margin-top: 20px;
    padding-right: 20px;
    max-height: 410px;
    position: relative;
    &__infinite-scroll {
      max-height: 370px;
      position: relative;
    }
    &__loader {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 20px;
    }
    &__loader-text {
      margin-left: 10px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 5px;
    }
  }
}
