@import 'src/styles/variables';

.breadcrumbs {
  font-size: 120%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  &.small {
    font-size: 90%;
  }

  &>span {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  &>span>a {
    color: $primary-blue;
    text-decoration: none;
    font-weight: bold;
  }
  &>span>a:hover {
    opacity: 0.7;
  }
  &__separator {
    margin: 0 $spacer-large;
    color: $neutral-color-600;
    &.small {
      margin: 0 $spacer-small;
    }
  }
  &__folder-icon {
    margin-right: $spacer-small;
    color: $neutral-color-600;
  }
}
