@import 'src/styles/variables';

$template-card-border: 1px solid $primary-blue;

.template-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 229px;
  max-width: 315px;
  border-radius: 5px;
  height: 100%;
  cursor: pointer;

  &__direct-create {
    display: none;
    margin-top: 0%;
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 43px;
    right: 0;
    background-color: $neutral-color-900;
    transition: 0.5s ease;	
    border-radius: 5px 5px 0 0;
    &__create-btn {
      left: calc(89% - 50.5%);
      bottom: -32.7%;    
    }
    z-index: 1;
  }

  &--selected {
    border: $template-card-border;
  }

  &:hover,
  &:focus {
    .template-card__direct-create {
      display: block;
    }
  }

  &__img {
    min-width: 229px;
    max-width: 315px;
    height: 110px;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    border-radius: 5px 5px 0 0;

    &.blur {
      filter: blur(1px);
      cursor: auto;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &__library {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    background-color: $yellow-600;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    border-radius: 5px 0 0 0;
    padding: 10px 12px;
  }

  &__name {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: $primary-white;
    color: $primary-black;
    font-weight: $font-weight-bold;
    font-size: $font-size-small;
    padding: 12px;
    border-radius: 0 0 5px 5px;
    text-align: center;
    white-space: pre-wrap;
    z-index: 2;
  }

  &__no-template {
    text-align: center;
    color: $primary-black;
    font-size: $font-size-small;
    cursor: initial;
    pointer-events: none;
    white-space: pre-wrap;
  }
}
.library-selection__container__block__subcategories{
  .template-card{
    position: relative;
    float: left;
    width: 25%;
    min-width: auto;
    max-width: none;
    border: none;
    box-shadow: none;
    margin-bottom: 0;
    &:before{
      content: '';
      display: block;
      padding-top: 70.0%;
    }
    .inner-wrap{
      position: absolute;
      top: 7px;
      left: 5px;
      bottom: 7px;
      right: 5px;
      border: 1px solid $neutral-color-50;
      box-shadow: 0 4px 2px -2px $neutral-color-200;
      margin: 0;
      overflow: hidden;
      .template-card__img{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 40px;
        min-width: auto;
        max-width: none;
        height: auto;
        min-height: auto;
        border-radius: 5px 5px 0 0;
      }
      .template-card__name{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 40px;
      }
    }
    @media(max-width: 1392px){
      width: 33.33%;
    }
    @media(max-width: 1022px){
      width: 50%;
    }
    @media(min-width: 1668px){
      width: 20%;
    }
  }
}