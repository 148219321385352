@import 'src/styles/mixins';
@import 'src/styles/variables';

.library-selection {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .mc-banner{
    margin-bottom: 20px;
    .mc-logo{
      height: 50px;
      width: auto;
      margin-right: 15px;
      vertical-align: middle;
    }
    .mc-title{
      display: inline-block;
      font-size: bold;
      font-size: 40px;
      vertical-align: middle;
    }
  }

  &__error-banner {
    margin-left: auto;
    margin-right: auto;
  }

  &__container {
    width: 100%;
    height: 100%;
    flex-direction: row;
    overflow-y: auto;
    background-color: $neutral-color-50;

    &.panel-open {
      .library-selection__container__block {
        width: calc(100% - 100px);

        &__carousel {
          width: 100%;
        }
      }
    }

    &__block {
      display: flex;
      width: 94%;
      flex-direction: column;
      margin: 60px;
      margin-bottom: 20px;

      &__all {
        display: grid;
        margin: 20px 0;
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        grid-gap: 20px;
        position: relative;

        &__loading {
          position: absolute;
          top: 30%;
          left: calc(50% - 20px);
          z-index: 3;
        }
      }
      &__wrapper {
        .flight-carousel-wrapper {
          left: 19px !important;
          .flight-carousel{
            -webkit-overflow-scrolling: touch;
            &::-webkit-scrollbar-track{
              -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
              border-radius: 10px;
              background-color: #F5F5F5;
            }
            &::-webkit-scrollbar{
              height: 0;
              width: 0;
              background-color: #F5F5F5;
            }
            &::-webkit-scrollbar-thumb{
              border-radius: 10px;
              -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
              background-color: rgb(85,85,85);
              background-color: rgba(85,85,85,0.25);
            }
          }
        }
      }
      &__carousel {
        width: 100%;
        margin-left: -30px;

        .flight-carousel-toggle {
          opacity: 1 !important;
          z-index: 1 !important;
          background-image: none !important;

          &__button {
            width: 42px;
            height: 42px;
          }

          &--right {
            .flight-carousel-toggle__button {
              margin-left: 79px !important;
            }
            @media screen and (max-width: 800px) {
              right: 11px !important;
            }
          }

          &--left {
            .flight-carousel-toggle__button {
              margin-left: -21px !important;
            }
          }
        }
        .flight-carousel-item {
          margin: 0 10px !important;
        }
      }

      &__title {
        margin-top: -45px;
        align-items: left;
        font-weight: $font-weight-bold;
      }

      &__banner {
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-top: 64px;
      }

      &__filter-bar {
        margin-top: 20px;
      }

      &__subcategories {
        width: 100%;
        margin: 20px 0!important;

        &__carousel {

        &__place {
          padding: 80px;
          margin-left: 50px;
        }
          .flight-carousel {
            display: grid;
            grid-auto-flow: column;
            grid-gap: 10px;
            grid-auto-columns: min-content;
            grid-row: 1;
          }
          .flight-carousel-item {
            margin: 0;
            width: 100%;
          }
          .flight-carousel-toggle {
            align-items: flex-start !important;

            &__button {
              margin-top: 145px !important;
            }
          }
        }
      }

      &__subcategory {
        margin-right: 20px;
      }

      &__template-item {
        border: 1px solid #cccccc;
        padding: 0;
        display: flex;
        flex-direction: row;
        background-color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
        box-shadow: none;
        transition: box-shadow 400ms;
        &:hover {
          @include box-shadow;
          transition: box-shadow 400ms;
        }

        &:focus {
          outline: none;
          box-shadow: inset 0 0 10px rgba(68, 68, 68, 0.6);
        }

        &__image-box {
          display: flex;
          justify-content: center;
          align-items: center;

          &__img {
            width: 150px;
            height: 150px;
            padding: 20px;
          }
        }

        &__text-box {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 30px 30px 30px 0;
          @include xs {
            padding: 20px 20px 20px 0;
          }
          @include md {
            padding: 30px 30px 30px 0;
          }

          &__title {
            font-weight: bold;
            font-size: 160%;
            text-align: left;
          }

          &__description {
            text-align: left;
            font-size: 150%;
            line-height: 1.5em;
            margin-top: 20px;
            color: $neutral-color-800;
          }

          &__content {
            font-size: 120%;
            margin-top: 20px;
            color: $neutral-color-500;
          }

          &__elements {
            font-size: 120%;
            margin-top: 20px;
            display: flex;
            flex-direction: row;

            &__element {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-weight: bold;
              margin-right: 20px;

              img {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}
