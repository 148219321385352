@import 'styles/variables.scss';

.folder-modal {

  &__content {
    padding: $spacer-2xlarge*2 $spacer-2xlarge;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      margin-left: $spacer-large;
    }
  }

  &__error-message {
    color: $error-color;
    text-align: center;
    padding: $spacer-large;
  }

}
