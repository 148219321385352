@import 'src/styles/variables';

.streamlined-content {
  .dynamic-attr-wrapper, .dynamic-attr {
    border: 1px solid #CDD0D7;
    border-radius: 2px;
    display: inline-block;
    margin: 0px 3px;
    padding: 0px 3px;
    cursor: pointer;
    margin-bottom: 2px;
    word-break: break-all;
    .dynamic-attr__default-value {
      color: #0851DF;
      padding-left: 4px;
    }
  }

  &__icon {
    position: relative;
    top: $spacer-xsmall;
    margin-left: $spacer-large;
  }
  &__body {
    color: $neutral-color-600;
    font-size: $font-size-xsmall;
  }
  &__title {
    font-weight: $font-weight-bold;
    font-size: $font-size-medium;
    margin-left: $spacer-medium;
    margin-right: $spacer-medium;
    margin-bottom: $spacer-large;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__disclaimer {
    display: flex;
    padding: $spacer-large;
    justify-content: center;
    color: $neutral-color-600;
    font-size: $font-size-xsmall;
  }

  &__collapse {
    margin-top: 15px;
    width: 108%;
    border-bottom-left-radius: 6.4px;
    border-bottom-right-radius: 6.4px;
    margin-left: -10.5px;
    margin-bottom: -15px;
    background-color: $neutral-color-100;
    height: 24px;
    display: flex;
    svg {
      margin-top: $spacer-medium;
      transform: rotate(180deg);
    }
    &:hover {
      background-color: $blue-300;
      svg {
        fill: $primary-white;
        transform: rotate(360deg);
      }
    }

    &__polygon {
      align-content: center;
      margin-left: auto;
      margin-right: auto;
      fill: $neutral-color-600;
    }
  }

  &__tabs {
    display: flex;
    margin-left: $spacer-medium;
    margin-bottom: $spacer-large;
    color: $neutral-color-600;
    font-size: $font-size-small;
    &__first {
      &--selected {
        color: $primary-blue;
      }
    }
    :hover {
      color: $primary-blue;
    }
    &__second {
      margin-left: $spacer-large;
      &--selected {
        color: $primary-blue;
        margin-left: $spacer-large;
      }
    }
  }

  &__preview {
    padding: 15px;
    border: 0.5px solid $teal-600;
    border-radius: $border-radius * 3;
    display: flex;
    flex-direction: column;
    margin-left: $spacer-medium;
    margin-right: $spacer-medium;
    align-self: center;
    text-align: left;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 300.5px;

    &__header-img {
      border-top-left-radius: $border-radius * 2;
      border-top-right-radius: $border-radius * 2;
      width: 116.4%;
      height: 85px;
      object-fit: cover;
      margin-left: -15px;
      margin-top: -15px;
    }
    &__header {
      margin-top: 10px;
      font-weight: 600;
      font-size: 10px;
      line-height: 20px;
      letter-spacing: -0.192px;
      color: rgba(0, 0, 0, 0.401863);
      &__wrap {
        max-width: 128px;
      }
    }
    &__title {
      font-size: 16px;
      font-weight: bold;
      display: flex;
      line-height: 24px;
      flex-direction: row;
      width: 150px;
      align-items: center;
      word-wrap: break-word;
      z-index: 5;

      &__wrap {
        max-width: 168px;
      }
    }
    &__description {
      width: 100%;
      font-size: 11px;
      margin: 5px 0 0 0;
      line-height: 1.3em;
      margin-bottom: 10px;
      &__showmore {
        font-size: $font-size-small;
        color: $primary-blue;
        cursor: pointer;
      }
    }
    &__button {
      &--primary {
        color: #ffffff;
        background-color: $primary-blue;
        align-self: center;
        text-align: center;
        margin: 5px;
        padding: 6px;
        border-radius: 30px;
        min-width: 128px;
        max-height: 18px;
        font-size: 11px;
      }
      &--secondary {
        color: $primary-blue;
        background-color: #ffffff;
        border: 1px solid $primary-blue;
        align-self: center;
        text-align: center;
        margin: 5px;
        padding: 6px;
        border-radius: 30px;
        min-width: 128px;
        max-height: 18px;
        font-size: 11px;
      }
    }
    &__link {
      cursor: pointer;
      color: $primary-blue;
    }
  }

  &__preview-detailed {
    overflow-y: auto;
    overflow-x: hidden;
    border: 0.5px solid $teal-600;
    border-radius: $border-radius * 3;
    max-height: 324px;
    margin-left: 5px;
    margin-right: 9px;
    padding: 13px;
    &__header-img {
      position: relative;
      margin-left: -40px;
      width: calc(100% + 80px);
      height: 110px;
      object-fit: cover;
      margin-top: 15px;
    }

    &__header {
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: -0.192px;
      color: $neutral-color-700;
      font-weight: 600;
      font-size: 10px;
      line-height: 20px;
      letter-spacing: -0.192px;
      color: rgba(0, 0, 0, 0.401863);
    }

    &__title {
      font-size: 16px;
      font-weight: bold;
      display: flex;
      line-height: 24px;
      flex-direction: row;
      width: 150px;
      align-items: center;
      word-wrap: break-word;
      z-index: 5;

      &__wrap {
        max-width: 198px;
      }
    }

    &__description {
      width: 100%;
      font-size: $font-size-xsmall;
      margin: 5px 0 0 0;
      line-height: 1.3em;
      min-height: 55px;
      text-overflow: ellipsis;
      margin-bottom: 10px;
    }
  }
}
