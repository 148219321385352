@import 'src/styles/variables';
@import 'src/styles/mixins';

.content-engagement-table {
  margin-top: $spacer-2xlarge + $spacer-large;
  border: $border-width solid map-get($neutral-colors, '100');

  .flight-table-header, .flight-table-row__cell {
    &:not(:first-child) {
      border-left: $border-width solid map-get($neutral-colors, '100');
      padding-left: $spacer-xlarge;
    }
  }

  .flight-table-header {
    color: map-get($neutral-colors, '900');
    background-color: map-get($neutral-colors, '50');
  }

  tbody {
    td:first-child {
      width: 20%;
    }
    td:last-child {
      width: 17%;
    }
  }

  .flight-table-row__cell > .flight-tooltip {
    width: 100%;

    .flight-tooltip__content {
      display: flex;

      svg {
        width: 20px;
        height: 20px;
        fill: map-get($neutral-colors, '600');
        margin-right: $spacer-small;
      }

      .content-engagement-table__shrinkable-cell {
        flex-grow: 1;
        width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow:ellipsis;
      }
    }
  }

  &__component-name {
    margin-left: $spacer-2xlarge;
  }

  &__shrinkable-cell, &__component-type {
    text-transform: capitalize;
  }
}