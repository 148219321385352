@import "src/styles/variables";

.delay {
  width: 70px;
  height: 70px;
  cursor: grab;
  border: none;
  background-color: $yellow-300;
  display: flex;
  position: relative;
  border-radius: 100px;
  text-align: center;
  padding: $spacer-medium;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  &:active {
    cursor: grabbing;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }

  &__remove {
    position: absolute;
    top: -30px;
    right: 0;
    padding: 5px 10px;
    text-decoration: none;
    cursor: default;
    color: $neutral-color-base;
    &:hover {
      color: $error-color;
      background-color: $neutral-color-50;
      cursor: pointer;
      border-radius: $border-radius;
    }
  }
}
