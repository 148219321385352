@import "src/styles/variables";

.journey {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: row;

  &__wrapper {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
  }

  &__node {
    padding: 0;
    margin: 0;
    width: 300px;
    height: auto;
    background-color: transparent;
    border-radius: $border-radius*2;
    border: 1px solid transparent;
  }

  &__start {
    padding: 0;
    border: none;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    &:hover {
      border: 1px solid $primary-blue;
    }
  }

  &__finish {
    padding: 0;
    border: none;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    &:hover {
      border: 1px solid $primary-blue;
    }
  }

}
