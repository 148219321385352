@import 'styles/variables.scss';

.push-interval {
  padding-top: $spacer-2xlarge;

  &__title {
    font-weight: $font-weight-bold;
    font-size: $font-size-small;
    padding-bottom: $spacer-large;
  }
  &__delay {
    font-weight: $font-weight-bold;
    font-size: $font-size-small;
    padding-top: $spacer-large;
    padding-bottom: $spacer-large;
  }
  &__body {
    font-size: $font-size-small;
    color: $neutral-color-600;
  }
}
