@import 'src/styles/variables.scss';

.push-action-edit {
  width: 100%;
  margin-right: 20px;
  padding: 20px 0px;
  .notification-type {
    margin-top: 10px;
  }

  .tab-section {
    margin-top: 30px;
    &__panels {
      margin-top: 15px;
      .flight-text-area {
        margin-top: 10px;
      }
    }
  }

  &__heading {
    font-weight: bold;
    margin: 30px 0 5px;
  }

  &__description {
    font-size: 90%;
    margin: 0 0 10px 0;
    color: $neutral-color-700;
  }

  &__input {
    margin-top: 10px;
  }

  &__inline {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    &:last-child {
      margin-bottom: 40px;
    }
  }

  &__subline {
    margin-top: 10px;
  }

  &__number,
  &__limit {
    max-width: 50px;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__limit {
    max-width: 100px;
  }

  &__select {
    display: inline-flex;
    width: auto;
    max-width: 100px;
  }

  &__limit-selector {
    margin: 0 10px;
  }

  &__checkbox {
    position: relative;
  }

  &__content-preview {
    .multistep_content-list__content__right {
      position: relative;
    }
    .multistep_content-list__content__left__container__item__search {
      position: sticky;
      top: -4% !important;
      min-width: 35.5%;
    }
    .multistep_content-list__content__left__container {
      padding-top: 30px;
    }
    .content-modal-preview {
      position: absolute;
      left: 50%;
      top: 15% !important;
    }
    .content-modal-preview__preview-body__content {
      position: relative;
    }

    .preview__footer-text {
      top: 104%;
      right: 0;
      left: 21%;
      position: relative;
    }

  }

  .flight-tab {
    ul {
      padding: 0;
    }
    &--list {
      display: flex;
      li {
        cursor: pointer;
        list-style: none;
        margin-right: 10px;
        padding: 0px 5px;
        &:active,
        &:focus {
          outline: none;
        }
      }
      &__item {
        .required {
          margin-left: 3px;
          color: red !important;
        }
      }
    }
    &--selected {
      border-bottom: 3px solid $primary-blue;
    }
    .visible {
      visibility: visible;
    }
    .hidden {
      display: none;
    }
  }
}
