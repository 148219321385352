
$footer-height: 50px;

.entry-criteria-edit {
  padding: 25px;
  height: 100%;

  &-main {
    display: flex;
    height: calc(100% - #{$footer-height});
    &__nav {
      display: flex;
      flex-direction: column;
      width: 250px;
      margin-right: 5px;
      a {
        color: black;
        text-decoration: none;
        padding: 20px;
        margin-bottom: 3px;
        opacity: 0.8;
        border: 2px solid white;
        &.selected {
          color: #0851DF;
          font-weight: bold;
        }
        &:hover {
          opacity: 1.0
        }
      }
    }
    &__content {
      flex: 1;
      padding: 10px;
      border: 1px solid #ddd;
      // override
      .audience-iframe .flight-snackbar {
        margin-top: 0px;
      }
      p, h2, h3 {
        padding: 0;
        margin: 0;
      }

      .hidden {
        display: none;
      }

      .show {
        padding: 10px;
      }

    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height:  $footer-height;
    margin-top: 10px;
    button {
      margin-left: 10px;
    }
  }

}