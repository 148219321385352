
.trigger-edit {

  .description {
    margin-top: 20px;
  }

  .flex-container {
    display: flex;
    margin-top: 30px;
  }

  .flex {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    .flight-select {
      margin-top: 10px;
    }
  }

  .new-trigger {
    margin-top: 30px;
  }

  .remove {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

}