.basic-form {

  &__custom-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 10px;
  }

  &__custom-field {
    display: flex;
    width: 100%;
    margin-top: 25px;
    flex-direction: column;

    &:first-child {
      margin-top: 0;
    }
  }
}
