
$info-margin: 20px;

.location-preview {
  position: relative;

  &__trigger {
    font-style: underline;
    cursor: pointer;
  }

  &__body {
    display: flex;
    padding: 25px;
    z-index: 2;
    position: absolute;
    bottom: 100%;
    left: 98%;
    transform: translateX(-20%);
    background: white;
    width: 550px;
    max-width: 550px;
    border: 1px solid #ddd;

    &.full-screen {
      flex-direction: column;
      margin-left: -100px;
      max-width: 320px;
      .map {
        margin-top: 10px;
      }
    }

    .info {
      width: 500px;
      max-width: 500px;
      padding-right: 10px;
      .address {
        margin-top: $info-margin;
      }
      .label {
        margin-top: $info-margin;
        .label-tag {
          display: inline-block;
          margin-right: 8px;
        }
      }
      .created_at {
        margin-top: $info-margin;
        color: rgb(187, 184, 184);
        font-style: italic;
      }
    }
  }

}