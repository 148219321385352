@import 'src/styles/variables';
$info-margin: 20px;

.complex-rule-preview {
  position: relative;

  &__trigger {
    font-style: underline;
    cursor: pointer;
    &--active {
      color: $primary-blue;
    }
  }

  &__body {
    display: flex;
    padding: 25px;
    z-index: 2;
    position: absolute;
    bottom: 100%;
    left: 98%;
    &--more-left {
      left: 10vw;
    }
    transform: translateX(10%) translateY(15%);
    background: white;
    width: 550px;
    max-width: 1050px;
    border: 1px solid #ddd;
    &__info {
      padding: 15px;
      background: $neutral-color-100;
    }
    flex-direction: column;
    margin-left: -100px;
    max-width: 580px;
  }
}
