@import 'src/styles/variables';

.multistep_content-list {
  width: 100%;
  &__content {
    display: flex;
    flex-direction: row;
    min-height: 500px;
    &__left {
      scroll-behavior: smooth !important;
      flex: 1;
      &__checkbox {
        position: relative;
        top: 25px;
        margin-left: 6px;
      }

      &__container {
        overflow-y: auto;
        max-height: 480px;
        padding-right: 10px;
        padding-top: 70px;

        &__item {
          width: 367px;
          border: 1.5px solid rgb(221, 222, 224);
          border-radius: 5px;
          padding: 15px;
          margin-bottom: 15px;
          background: white;
          word-break: break-all;
          cursor: pointer;
          z-index: 10;
          outline: none;
          margin-left: 30px;

          &:hover {
            background: rgba(218, 246, 255, 0.3);
            & .multistep_content-list__content__left__container__item--edit {
              visibility: visible;
              opacity: 0.3;
            }
            & .multistep_content-list__content__left__container__item--edit__shadow {
              visibility: visible;
              opacity: 0;
            }
          }

          &__loader {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: 20px;
          }
          &__loader-text {
            margin-left: 10px;
          }
          &__search {
            top: 28%;
            position: fixed;
            min-width: 31.5%;
          }

          &--selected {
            margin-left: 30px;
            border: 2px solid $primary-blue;
            width: 367px;
            border-radius: 5px;
            padding: 15px;
            margin-bottom: 15px;
            background: white;
            word-break: break-all;
            cursor: pointer;
            &:hover {
              background: rgba(218, 246, 255, 0.3);
              & .multistep_content-list__content__left__container__item--edit {
                visibility: visible;
                opacity: 0.3;
              }
              & .multistep_content-list__content__left__container__item--edit__shadow {
                visibility: visible;
                opacity: 0;
              }
            }
          }

          &__header-icon {
            margin-right: 15px;
          }

          &__title {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: #1e2b4a;
            padding-bottom: 10px;
          }
          &__body {
            padding-bottom: 10px;
          }
          &__icon {
            margin-left: 90%;
          }
        }

        &__item--edit {
          float: right;
          cursor: pointer;
          visibility: hidden;
          &__shadow {
            margin-left: -25px;
            margin-bottom: -7px;
            cursor: pointer;
            border-radius: 50%;
          }
          &__pen {
            margin-left: -18px;
            margin-top: -10px;
            cursor: pointer;
          }

          &:hover {
            opacity: 1 !important;
            & .multistep_content-list__content__left__container__item--edit__shadow {
              opacity: 1 !important;
            }
          }
        }
      }
    }
    &__right {
      flex: 1;
      .content-modal-preview {
        top: 35% !important;
      }
      .preview__footer-text {
        right: 15%;
      }
    }
  }

  &__footer {
    margin-top: -1.5%;
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 10px;
    }
  }
}
