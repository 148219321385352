@import 'src/styles/variables.scss';

.push-edit {
  width: 100%;
  margin-right: 20px;
  padding: 20px 0px;
  .notification-type {
    margin-top: 10px;
  }

  &__amplify {
    &__header {
      margin-left: $spacer-medium;
      position: relative;
      top: -4px;
    }
    &__description {
      color: $neutral-color-600;
      font-size: $font-size-xsmall;
    }
  }

  .tab-section {
    margin-top: 30px;
    display: flex;
    width: 100%;
    flex-direction: column;
    &__panels {
      margin-top: 15px;
      .flight-text-area {
        margin-top: 10px;
      }
    }
  };

  &__heading {
    font-weight: bold;
    margin: 30px 0 5px;
  }

  &__description {
    font-size: 90%;
    margin: 0 0 10px 0;
    color: $neutral-color-700
  }

  &__input {
    margin-top: 10px;
  }

  &__inline {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    &:last-child {
      margin-bottom: 40px;
    }
  }

  &__subline {
    margin-top: 10px;
  }

  &__number, &__limit {
    max-width: 50px;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__limit {
    max-width: 100px;
  }

  &__select {
    display: inline-flex;
    width: auto;
    max-width: 100px;
  }

  &__limit-selector {
    margin: 0 10px;
  }

  &__checkbox {
    position: relative;
  }

  .flight-tab {
    ul {
      padding: 0;
    }
    &--list {
      display: flex;
      li {
        cursor: pointer;
        list-style: none;
        margin-right: 10px;
        padding: 0px 5px;
        &:active, &:focus {
          outline: none;
        }
      }
      &__item {
        .required {
          margin-left: 3px;
          color: red !important;
        }
      }
    }
    &--selected {
      border-bottom: 3px solid $primary-blue;
    }
    .visible {
      visibility: visible;
    }
    .hidden {
      display: none;
    }
  }
}
