@import 'src/styles/mixins';
@import 'src/styles/variables';


.multi-create {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;

  &__main {
    display: flex;
    height: 100%;
    width: 120%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;

    &__flow {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: $spacer-2xlarge*5;
      box-sizing: border-box;
      background-color: $comp-background-color;


      .render-container {
        position: relative;

        .vertical-line {
          position: absolute;
          border: 1px solid #B4B8C3;
          z-index: -1;
          height: 80%;
          margin-left: -51px;
          margin-top: 10px;
        }
        .column {
          display: flex;
          flex-direction: column;

          &-row {
            display: flex;
            position: relative;
            .horizontal-line {
              width: 50px;
              position: absolute;
              width: 50px;
              margin-left: -52px;
              border-bottom: 1px solid black;
            }
            .item {
              margin: 5px 0px;
            }
          }
        }
      }

      &-row {
        position: relative;
        display: flex;
        &-arrows {
          position: absolute;
          left: -75px;
        }
      }
    }

    &__progress {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex: 1;
      padding: $spacer-2xlarge*4;
      width: 100%;
      height: 100%;
    }

    &__error {
      color: red;
      text-align: center;
      padding: 60px;
    }

    &__empty {
      text-align: center;
      padding: 60px;
    }
  }

  &__finish-icon-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-left: 100px;
  }

}
